import React, { useEffect, useState } from "react";
import MotoFoto from "../../../img/fotosProductos/motoWeb.jpg";
import MotoFotoCel from "../../../img/fotosProductos/motoInstagram.jpg";
import MotoFotoTablet from "../../../img/fotosProductos/motoFacebook.jpg";
import { Card, Row, Col } from "react-bootstrap";
import "./productos.css";
import CotizadorMoto from "../Cotizador/CotizadorMoto";
import TablaMoto from "./TablaMoto";
// import coberturaMundial from "../../../img/iconosCaracteristicasProductos/coberturaMundial.png";
import contratacionOnLine from "../../../img/iconosCaracteristicasProductos/contratacionOnLine.png";
import laMejorPropuesta from "../../../img/iconosCaracteristicasProductos/laMejorPropuesta.png";
import pagosElectronicos from "../../../img/iconosCaracteristicasProductos/pagosElectronicos.png";

const Motocicleta = (props) => {
  return (
    <section>
      <div>
        <Card className="bg-dark border text-white">
        <Card.Img
            src={MotoFoto}
            alt="Imagen producto motocicleta"
            className="w-100 wImgDos d-none d-md-block d-lg-block d-xl-block d-xxl-block "
          />
          <Card.Img
            src={MotoFotoTablet}
            alt="Imagen producto motocicleta"
            className="w-100 wImgDos d-none d-sm-block d-md-none"
          />
          <Card.Img
            src={MotoFotoCel}
            alt="Imagen producto motocicleta"
            className="w-100 wImgDos d-xs-block d-sm-none"
          />
          <div className="poligonoProductoDos bg-white d-none d-lg-block d-xl-block d-xxl-block"></div>
          <Card.ImgOverlay className="ms-auto d-block d-lg-none bgTextSm">
            <Card.Title className="tituloProductoMd fuenteMontserrat fw-bold text-center">
              Seguro de Moto
            </Card.Title>
            <Card.Text className="subtituloProductoMd fuenteMontserrat text-center">
              Pedi tu cotización online y obtene un 10% de descuento en el
              costo de tu seguro.
            </Card.Text>
          </Card.ImgOverlay>
          <Card.ImgOverlay className="poligonoProducto ms-auto d-none d-lg-block d-xl-block d-xxl-block">
            <Card.Title className="tituloProducto fuenteMontserrat fw-bold">
              Seguro de Moto
            </Card.Title>
            <Card.Text className="subtituloProducto fuenteMontserrat">
            Pedi tu cotización online y obtene un 10% de descuento en el
              costo de tu seguro.
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
    
      <section className="fuenteMontserrat mt-5 container-fluid">
          <h2 className="text-center fw-bold fuenteAzul">Características</h2>
          <Row className="d-flex justify-content-evenly my-3 py-3 ">
          {/* <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={coberturaMundial} alt="" className="wIconosCaract" />
                <p className="fw-bold text-center mt-3 fuenteIconosCaract">
                  Cobertura Mundial
                </p>
              </div>
            </Col> */}
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img
                  src={contratacionOnLine}
                  alt="icono de contratacion online"
                  className="wIconosCaract"
                />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                  Contratación Online
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={laMejorPropuesta} alt="icono de la mejor propuesta" className="wIconosCaract" />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                  La mejor propuesta
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={pagosElectronicos} alt="icono medios de pago electronicos" className="wIconosCaract" />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract ">
                  Pago Electrónicos
                </p>
              </div>
            </Col>
          </Row>
        </section>


      <section className="container mt-5">
        <TablaMoto></TablaMoto>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={8}>
        <CotizadorMoto
          consultarProspectos={props.consultarProspectos}
          consultarProvincia={props.consultarProvincia}
          province={props.province}
        ></CotizadorMoto>
         </Col>
        </Row>
      </section>
    </section>
  );
};

export default Motocicleta;
