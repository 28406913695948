import React from "react";
import Bici from "../../../img/fotosProductos/bicicletaWeb.jpg";
import BiciCel from "../../../img/fotosProductos/bicicletaInstagram.jpg";
import BiciTablet from "../../../img/fotosProductos/bicicletaFacebook.jpg";
import { Card, Row, Col } from "react-bootstrap";
import "./productos.css";
import CotizadorBici from "../Cotizador/CotizadorBici";
import TablaCobBicicletas from "./TablaCobBicicletas";
// import coberturaMundial from "../../../img/iconosCaracteristicasProductos/coberturaMundial.png";
import contratacionOnLine from "../../../img/iconosCaracteristicasProductos/contratacionOnLine.png";
import laMejorPropuesta from "../../../img/iconosCaracteristicasProductos/laMejorPropuesta.png";
import pagosElectronicos from "../../../img/iconosCaracteristicasProductos/pagosElectronicos.png";

const Bicicleta = (props) => {
  return (
    <section>
      <div>
        <Card className="bg-dark border text-white">
          <Card.Img
            src={Bici}
            alt="Imagen producto seguro bicicleta"
            className="w-100 wImgDos d-none d-md-block d-lg-block d-xl-block d-xxl-block "
          />
          <Card.Img
            src={BiciTablet}
            alt="Imagen producto seguro bicicleta"
            className="w-100 wImgDos d-none d-sm-block d-md-none"
          />
          <Card.Img
            src={BiciCel}
            alt="Imagen producto seguro bicicleta"
            className="w-100 wImgDos d-xs-block d-sm-none"
          />
          <div className="poligonoProductoDos bg-white d-none d-lg-block d-xl-block d-xxl-block"></div>
          <Card.ImgOverlay className="ms-auto d-block d-lg-none bgTextSm">
            <Card.Title className="tituloProductoMd fuenteMontserrat fw-bold text-center">
              Bicicleta - Monopatín
            </Card.Title>
            <Card.Text className="subtituloProductoMd fuenteMontserrat text-center">
              Pedi hoy tu cotización online.
            </Card.Text>
          </Card.ImgOverlay>
          <Card.ImgOverlay className="poligonoProducto ms-auto d-none d-lg-block d-xl-block d-xxl-block">
            <Card.Title className="tituloProducto fuenteMontserrat fw-bold">
              Bicicleta - Monopatín
            </Card.Title>
            <Card.Text className="subtituloProducto fuenteMontserrat">
              Pedi hoy tu cotización online.
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
      <section className="fuenteMontserrat mt-5 container-fluid">
        <h2 className="text-center fw-bold fuenteAzul">Características</h2>
        <Row className="justify-content-evenly my-3 py-3 w-100">
          {/* <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={coberturaMundial} alt="" className="wIconosCaract" />
                <p className="fw-bold text-center mt-3 fuenteIconosCaract">
                  Cobertura Mundial
                </p>
              </div>
            </Col> */}
          <Col md={2} lg={2} xl={2}>
            <div className="text-center">
              <img
                src={contratacionOnLine}
                alt="icono contratacion online"
                className="wIconosCaract"
              />
              <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                Contratación Online
              </p>
            </div>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <div className="text-center">
              <img
                src={laMejorPropuesta}
                alt="icono la mejor propuesta"
                className="wIconosCaract"
              />
              <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                La mejor propuesta
              </p>
            </div>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <div className="text-center">
              <img
                src={pagosElectronicos}
                alt="icono medios de pago electronicos"
                className="wIconosCaract"
              />
              <p className=" fw-bold  text-center mt-3 fuenteIconosCaract ">
                Pago Electrónicos
              </p>
            </div>
          </Col>
        </Row>
      </section>
      <section className="container mt-5">
        <TablaCobBicicletas></TablaCobBicicletas>
        <Row className="justify-content-center my-3">
          <Col xs={12} sm={12} md={12} lg={8}>
            <CotizadorBici
              consultarProspectos={props.consultarProspectos}
            ></CotizadorBici>
          </Col>
        </Row>
      </section>
    </section>
  );
};

export default Bicicleta;
