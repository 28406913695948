import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import logoOCS from "../../img/logoOCS.png";
import "./navigation.css";
import { Link } from "react-router-dom";

const Navigation = (props) => {
  return (
    <Navbar collapseOnSelect bg="white" expand="lg" className="bg-dark navH">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={logoOCS} alt="logo de ocs" className="pe-5 me-5 w-logo" />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto mt-2 d-flex align-content-end justify-content-end ">
            <Nav.Link className="sul ">
              <div className="sli">
                <Link to="/" className="fuenteNav fs-6 text-secondary sli ">
                  INICIO
                </Link>
              </div>
            </Nav.Link>

            <Nav.Link className="fuenteNav text-secondary text-decoration-none ">
              <NavDropdown
                title="PRODUCTOS"
                id="navbarScrollingDropdown"
                className="fuenteNav  text-secondary sul"
              >
                <NavDropdown.Item>
                  <Link
                    className="fuenteNav  text-secondary text-decoration-none sli"
                    to="/productos/ap"
                  >
                    Accidentes Personales
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="fuenteNav text-secondary text-decoration-none sli"
                    to="/productos/av"
                  >
                    Asistencia Al Viajero
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="fuenteNav  text-secondary text-decoration-none"
                    to="/productos/automotor"
                  >
                    Seguro de Auto
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="fuenteNav  text-secondary text-decoration-none"
                    to="/productos/motocicleta"
                  >
                    Seguro de Moto
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="fuenteNav  text-secondary text-decoration-none"
                    to="/productos/bicicleta"
                  >
                    Bicicleta
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="fuenteNav  text-secondary text-decoration-none"
                    to="/productos/celular"
                  >
                    Celular
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="fuenteNav  text-secondary text-decoration-none"
                    to="/productos/praxis"
                  >
                    Praxís Médica
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="fuenteNav  text-secondary text-decoration-none w-100"
                    to="/productos/hogar"
                  >
                    Hogar
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav.Link>

            <Nav.Link href="#link" className="navIcon fw-bold sul">
              <div className="sli">
                <a
                  href="/#oficinas"
                  className="fuenteNav fs-6 text-secondary sli "
                >
                  {" "}
                  NUESTRAS OFICINAS
                </a>
              </div>
            </Nav.Link>
            <Nav.Link href="#link" className="navIcon fw-bold sul">
              <div className="sli">
                {props.loginCtrAd === false ? (
                  <a href="/#contacto" className="fuenteNav text-secondary">
                    {" "}
                    CONTACTO
                  </a>
                ) : (
                  <a href="/login/admin" className="fuenteNav text-secondary">
                    {" "}
                    ADMINISTRADOR
                  </a>
                )}
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
