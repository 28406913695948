import React from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
const DescripcionProductoHogar = () => {
  return (
    <div className="fuenteMontserrat mt-5">
      <Row>
        <Col md={6}>
          <h2 className="fw-bold fuenteAzul">Coberturas</h2>
          <p>
            Destinado a viviendas familiares de ocupación permanente. <br />
            Viviendas ubicadas en barrios privados. <br />
            Departamentos sin acceso directo a la calle.
          </p>
          <Accordion>
            <Accordion.Item eventKey="0" className="borderAcordion">
              <Accordion.Header >Incendio edificio y contenido</Accordion.Header>
              <Accordion.Body>
                Cobertura de daños materiales causados por la acción directa o
                indirecta del fuego al edificio y al contenido. <br />
                Incluye: <br />
                <li>Daños Materiales por Granizo</li>
                <li>Gastos de alojamiento</li>
                <li>Gastos de limpieza y remonición de escombros</li>
                <li>Huracán, vendaval, ciclón y tornado.</li>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="borderAcordion">
              <Accordion.Header>Cristales</Accordion.Header>
              <Accordion.Body>
                Cobertura para vidrios y espejos ubicados en posición vertical,
                tanto por rotura o rajadura.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="borderAcordion">
              <Accordion.Header>Daños por Agua</Accordion.Header>
              <Accordion.Body>
                Se cubre la pérdida de o los daños al contenido por la acción
                directa del agua, únicamente cuando sean causados por
                filtración, derrame, desborde o escape como consecuencia de
                rotura, obstrucción, falta o deficiencia en tanques, cañerías,
                válvulas o bombas. <br /> Incluye los Daños por agua al Edificio
                provocados por la acción directa del agua como consecuencia de
                rotura, obstrucción, falta o deficiencia en tanques, cañerías,
                válvulas o bombas
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="borderAcordion">
              <Accordion.Header>Robo Contenido</Accordion.Header>
              <Accordion.Body>
                Se cubre la pérdida por robo o hurto del mobiliario mientras se
                encuentre en la vivienda asegurada y que sea de propiedad del
                asegurado, de los miembros de su familia que convivan con él, de
                sus huéspedes y/o del personal que realiza tareas de servicio
                doméstico
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="borderAcordion">
              <Accordion.Header>Robo Equipos mobiles</Accordion.Header>
              <Accordion.Body>
                Se cubre la pérdida por robo de Filmadoras, Máquinas
                Fotográficas, Netbooks y Notebooks de uso exclusivamente
                familiar.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="borderAcordion">
              <Accordion.Header>Todo Riesgo Electrodomésticos</Accordion.Header>
              <Accordion.Body>
                Se otorga cobertura de daños materiales por causa accidental
                sufridos por equipos electrónicos y electrodomesticos
                exclusivamente para Televisores, Equipos de Audio, Equipos de
                video, Computadoras de escritorio y portatiles, Tablets,
                Lavarropas, Heladeras, Microondas, Secarropas, Cocinas
                eléctricas, hornos y anafes eléctricos y Lavavajillas.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="borderAcordion">
              <Accordion.Header>
                Todo Riesgo Portátiles en el mundo
              </Accordion.Header>
              <Accordion.Body>
                Daños causados por Incendio, Rayo o Explosión y por Robo o
                Accidente que afectare a los aparatos o equipos electrónicos
                Portátiles. <br />
                <ul>
                  <li>
                    Computadoras portátiles, notebooks, tablets, netbooks, Ipad,
                    Ibook.
                  </li>
                  <li>Cámara de fotos y/o filmadoras y sus accesorios.</li>
                  <li>Equipos de audio y video portátiles y sus accesorios.</li>
                  <li>Equipos de posicionamiento global (GPS)</li>
                  <li>Celulares</li>
                  <li>Consolas de juegos portátiles</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={6}>
          <h3 className="fs-4 fwMontserrat mt-5">
            Condiciones y medidas de seguridad
          </h3>
          <ul>
            <li>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChek me-2 fs-5"
              />
              Vivienda de ocupación permanente.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Poseer cerraduras doble paleta o bidimensionales en todas las
              puertas de acceso y/o puertas a patios o jardines y/o las del
              edificio (en caso de tratarse de un departamento).
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Poseer rejas de protección de hierro en todas las ventanas y
              puertas con paneles de vidrio, ubicadas en la planta baja y primer
              piso, patios y/o jardines, que tienen acceso desde la calle.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Este edificada de medianera a medianera, no linda con un terreno
              baldío, obra en construcción o edificio abandonado. Cuente con
              muros de una altura mínima de 1,80 mts. que obstaculicen cualquier
              ingreso que no sea la puerta de entrada al edificio.
            </li>
          </ul>

          <h3 className="fs-4 fwMontserrat mt-3">Adionales</h3>
          <ul>
            <li>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChek me-2 fs-5"
              />
              Daños Materiales al Edificio y/o Contenido General en ocasión de
              Robo.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Gastos de alojamiento en caso de incendio
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Cobertura de Robo de Bicicletas
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default DescripcionProductoHogar;
