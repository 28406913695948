import React from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faTrashCan,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import { init } from "@emailjs/browser";
init("goeW6mKbl5HzsTzEV");

const FormularioUsuarios = (props) => {
  const navegacion = useNavigate();
  const eliminarUsuario = () => {
    Swal.fire({
      title: "¿Esta seguro de eliminar este usuario?",
      text: "¡Una vez eliminado no se puede recuperar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Borrar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const URL =
            process.env.REACT_APP_API_URL_USER + "/" + props.usuario._id;

          const respuesta = await fetch(URL, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          });
          console.log(respuesta);
          if (respuesta.status === 200) {
            Swal.fire(
              "¡Eliminado!",
              "El usuario fue correctamente eliminado.",
              "success"
            );
            props.consultarUser();
            navegacion("/login/admin");
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const handleEnviarDatos = async (e) => {
    Swal.fire({
      title: "¿Deseas enviar los datos de contacto a "+ props.usuario.nombre + "?",
      
      icon: "question",
      
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Enviar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
    
        const datos = {
        nombre: props.usuario.nombre,
        email: props.usuario.email,
        contrasena: props.usuario.contrasena,
      };
    emailjs
        .send("service_bi0bu0v", "template_phavvu8", datos, "goeW6mKbl5HzsTzEV")
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
            Swal.fire({
              position: 'center-center',
              icon: 'success',
              title: 'Los datos fueron enviados!',
              showConfirmButton: false,
              timer: 1500
            })
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
        
  }catch (error) {
    console.log(error);
  }
}
})
  }

  return (
    <tr>
      <td>{props.usuario._id}</td>
      <td>{props.usuario.nombre}</td>
      <td>{props.usuario.email}</td>
      <td>{props.usuario.contrasena}</td>
      <td>
        <button>
          <Link to={`/login/admin/editar-usuario/${props.usuario._id}`} placeholder="hola">
            <FontAwesomeIcon icon={faPenToSquare} className="fs-3 me-2" />
          </Link>
        </button>
        <button onClick={() => eliminarUsuario()}>
          <FontAwesomeIcon icon={faTrashCan} className="fs-3 me-2 text-danger" />
        </button>
        <button onClick={() => handleEnviarDatos()}>
          <FontAwesomeIcon icon={faEnvelope} className="fs-2 me-2 text-info"/>
        </button>
      </td>
    </tr>
  );
};

export default FormularioUsuarios;
