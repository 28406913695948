import React from "react";
import { Container, Navbar, Row, Col } from "react-bootstrap";
import Triunfo from "../../img/logoEmpresas/triunfo.png";
import Mercantil from "../../img/logoEmpresas/mercantil.png";
import Rus from "../../img/logoEmpresas/rus.png";
import Rivadavia from "../../img/logoEmpresas/rivadavia.png";

const PrincipalEmpresas = () => {
  return (
    <section className="fuenteMontserrat container">
      <h2 className="colorFuenteAzul text-center mt-5 mb-4 fs-2 fw-bold fuenteMontserrat">
        Compañías
      </h2>
      <Row className="text-center">
        <Col xs={6} sm={6} lg={3} className="container">
          <Navbar.Brand href="#home">
            <img src={Triunfo} alt="logo triunfo seguros" className="w60" />
          </Navbar.Brand>
        </Col>
        <Col xs={6} sm={6} lg={3} className="container">
          <Navbar.Brand href="#home">
            <img src={Mercantil} alt="logo mercantil andina" className="w60" />
          </Navbar.Brand>
        </Col>
        <Col xs={6} sm={6} lg={3} className="container">
          <Navbar.Brand href="#home">
            <img src={Rivadavia} alt="logo mercantil andina" className="w60" />
          </Navbar.Brand>
        </Col>

        <Col xs={6} sm={6} lg={3} className="container">
          <Navbar.Brand href="#home">
            <img src={Rus} alt="logo rio uruguay seguros" className="w60" />
          </Navbar.Brand>
        </Col>
      </Row>
      <Navbar bg="light" className="hPrincipalEmpresas bg-white">
        <Container></Container>
      </Navbar>
    </section>
  );
};

export default PrincipalEmpresas;
