import React from "react";
import AP from "../../../img/fotosProductos/artWeb.jpg";
import APCel from "../../../img/fotosProductos/artInstagram.jpg";
import APTablet from "../../../img/fotosProductos/artFacebook.jpg";
import { Card, Row, Col } from "react-bootstrap";
import CotizadorAP from "../Cotizador/CotizadorAP";
import DescripcionProductoAP from "./DescripcionProductoAP";
// import coberturaMundial from "../../../img/iconosCaracteristicasProductos/coberturaMundial.png";
import contratacionOnLine from "../../../img/iconosCaracteristicasProductos/contratacionOnLine.png";
import laMejorPropuesta from "../../../img/iconosCaracteristicasProductos/laMejorPropuesta.png";
import pagosElectronicos from "../../../img/iconosCaracteristicasProductos/pagosElectronicos.png";

const AccidentesPersonales = (props) => {
  return (
    <section>
      <div>
        <Card className="bg-dark border text-white">
          <Card.Img
            src={AP}
            alt="Imagen producto accidentes personales"
            className="w-100 wImgDos d-none d-md-block d-lg-block d-xl-block d-xxl-block "
          />
          <Card.Img
            src={APTablet}
            alt="Imagen producto accidentes personales"
            className="w-100 wImgDos d-none d-sm-block d-md-none"
          />
          <Card.Img
            src={APCel}
            alt="Imagen producto accidentes personales"
            className="w-100 wImgDos d-xs-block d-sm-none"
          />
          <div className="poligonoProductoCuatro bg-white d-none d-lg-block d-xl-block d-xxl-block"></div>
          <Card.ImgOverlay className="ms-auto d-block d-lg-none bgTextSm">
            <Card.Title className="tituloProductoDosMd fuenteMontserrat fw-bold text-center">
              Accidentes Personales
            </Card.Title>
            <Card.Text className="subtituloProductoDosMd fuenteMontserrat w-75 m-auto text-center">
              Para trabajadores, deportistas,{" "}
              <span>
                <br />
              </span>{" "}
              personal de salud,{" "}
              <span>
                <br />
              </span>{" "}
              estudiantes.{" "}
              <span>
                <br />
              </span>{" "}
              Por tu actividad o las 24hs
            </Card.Text>
          </Card.ImgOverlay>
          <Card.ImgOverlay className="poligonoProductoTres ms-auto d-none d-lg-block d-xl-block d-xxl-block">
            <Card.Title className="tituloProductoDos fuenteMontserrat fw-bold">
              Accidentes{" "}
              <span>
                <br />
                Personales
              </span>
            </Card.Title>
            <Card.Text className="subtituloProductoDos fuenteMontserrat">
              Para trabajadores, deportistas,{" "}
              <span>
                <br />
              </span>{" "}
              personal de salud,{" "}
              <span>
                <br />
              </span>{" "}
              estudiantes.{" "}
              <span className="d-none d-xxl-block">
                Por tu actividad o las 24hs
              </span>{" "}
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
      <section className="fuenteMontserrat mt-5 container-fluid">
        <h2 className="text-center fw-bold fuenteAzul">Características</h2>
        <Row className="justify-content-evenly mt-4 pt-4 ">
        {/* <Col md= {2} lg={2} xl={2}>
            <div className="text-center">
              <img src={coberturaMundial} alt="" className="wIconosCaract" />
              <p className="fw-bold text-center mt-3 fuenteIconosCaract">
                Cobertura Mundial
              </p>
            </div>
          </Col> */}
          <Col md= {2} lg={2} xl={2}>
            <div className="text-center">
              <img src={contratacionOnLine} alt="icono contratacion online" className="wIconosCaract" />
              <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                Contratación Online
              </p>
            </div>
          </Col>
          <Col md= {2} lg={2} xl={2}>
            <div className="text-center">
              <img src={laMejorPropuesta} alt="icono la mejor propuesta" className="wIconosCaract" />
              <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                La mejor propuesta
              </p>
            </div>
          </Col>
          <Col md= {2} lg={2} xl={2}>
            <div className="text-center">
              <img src={pagosElectronicos} alt="icono de medios de pago electronicos" className="wIconosCaract" />
              <p className=" fw-bold  text-center mt-3 fuenteIconosCaract ">
                Pago Electrónicos
              </p>
            </div>
          </Col>
        </Row>
      </section>
      <section className="container mt-3">
        <Row className="justify-content-center">
          <Col md={12}>
            <DescripcionProductoAP></DescripcionProductoAP>
          </Col>
          
          <Col xs={12} sm={12} md={12} lg={8} className="d-flex justify-content-center">
            <CotizadorAP
              consultarProspectos={props.consultarProspectos}
            ></CotizadorAP>
            <Col></Col>
          </Col>
        </Row>
      </section>
    </section>
  );
};

export default AccidentesPersonales;
