import React from "react";
import { Table, Button } from "react-bootstrap";
import LogoMa from "../../../img/logoEmpresas/mercantil.png";
import LogoTriunfo from "../../../img/logoEmpresas/triunfo.png";

const TablaCobHogar = () => {
  return (
    <div>
      <Table
        striped
        bordered
        hover
        responsive
        className="text-center fuenteMontserrat mt-5"
      >
        <thead>
          <tr>
            <th>Coberturas</th>
            <th>Plan A</th>
            <th>Plan B</th>
            <th>Plan C</th>
            <th>Plan D</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="">Aseguralo en</td>
            <td>
              <img
                src={LogoTriunfo}
                alt="Triunfo Seguros"
                className="logoTabla"
              />
            </td>
            <td>
              <img src={LogoMa} alt="Mercantil Andina" className="logoTabla" />
            </td>
            <td>
              <img
                src={LogoTriunfo}
                alt="Triunfo Seguros"
                className="logoTabla"
              />
            </td>
            <td>
              <img src={LogoMa} alt="Mercantil Andina" className="logoTabla" />
            </td>
          </tr>
          <tr>
            <td>Incendio Edificio</td>
            <td>$3.000.000</td>
            <td>$3.000.000</td>
            <td>$7.000.000</td>
            <td>$7.000.000</td>
          </tr>

          <tr>
            <td>Incendio Contenido</td>
            <td>$990.000</td>
            <td>$990.000</td>
            <td>$2.310.000</td>
            <td>$2.310.000</td>
          </tr>
          <tr>
            <td>Cristales</td>
            <td>$15.000</td>
            <td>$15.000</td>
            <td>$35.000</td>
            <td>$35.000</td>
          </tr>
          <tr>
            <td>Daños por Agua</td>
            <td>$75.000</td>
            <td>$75.000</td>
            <td>$75.000</td>
            <td>$75.000</td>
          </tr>
          <tr>
            <td>Robo Contenido</td>
            <td>$300.000</td>
            <td>$150.000</td>
            <td>$700.000</td>
            <td>$300.000</td>
          </tr>

          <tr>
            <td>Robo Equipos Móbiles</td>
            <td>$45.000</td>
            <td>-</td>
            <td>$105.000</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Todo Riesgo Electrodomésticos</td>

            <td>-</td>
            <td>$150.000</td>
            <td>-</td>
            <td>$300.000</td>
          </tr>
          <tr>
            <td>Todo Riesgo Portátiles en el Mundo</td>
            <td>-</td>
            <td>$150.000</td>
            <td>-</td>
            <td>$300.000</td>
          </tr>
          {/* <tr>
            <td>Costo del Seguro con débito automático</td>
            <td><span className="fs-3 text-primary fwMontserrat">$1.160</span> /mes</td>
            <td><span className="fs-3 text-primary fwMontserrat">$2.170</span> /mes</td>
            <td><span className="fs-3 text-primary fwMontserrat">$2.511</span> /mes</td>
            <td><span className="fs-3 text-primary fwMontserrat">$4.203</span> /mes</td>
          </tr> */}
          <tr>
            <td></td>
            <td className="text-center">
              <Button>
                <a
                  href="/productos/hogar/#cotizadorHogar"
                  className="text-white text-decoration-none"
                >
                  Cotizar
                </a>
              </Button>
            </td>
            <td className="text-center">
              <Button>
                <a
                  href="/productos/hogar/#cotizadorHogar"
                  className="text-white text-decoration-none"
                >
                  Cotizar
                </a>
              </Button>
            </td>
            <td className="text-center">
              <Button>
                <a
                  href="/productos/hogar/#cotizadorHogar"
                  className="text-white text-decoration-none"
                >
                  Cotizar
                </a>
              </Button>
            </td>
            <td className="text-center">
              <Button>
                <a
                  href="/productos/hogar/#cotizadorHogar"
                  className="text-white text-decoration-none"
                >
                  Cotizar
                </a>
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TablaCobHogar;
