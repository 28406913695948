import React, { useState } from "react";
import "./login.css";
import { Form, Button, Alert, Navbar, Container, Nav } from "react-bootstrap";
import { campoRequerido } from "../../Helpers/helpers";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";

const FormularioUsuariosNuevo = (props) => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [contrasena, setContrasena] = useState("");
  const URL = process.env.REACT_APP_API_URL_USER;
  const [error, setError] = useState(false);
  const navegacion = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      campoRequerido(nombre) &&
      campoRequerido(email) &&
      campoRequerido(contrasena)
    ) {
      const nuevoUsuario = {
        nombre: nombre,
        email: email,
        contrasena: contrasena,
      };
      try {
        const parametros = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(nuevoUsuario),
        };
        const respuesta = await fetch(URL, parametros);
        console.log(respuesta);
        if (respuesta.status === 201) {
          Swal.fire(
            "Usuario agregado",
            "El usuario fue agregado con exito",
            "success"
          );
        } else {
          Swal.fire(
            "Usuario no agregada",
            "Existen problemas con el servidor",
            "error"
          );
        }
        e.target.reset();
        props.consultarUser();
        navegacion("/login/admin");
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(true);
      console.log("mensaje de error");
    }
  };

  return (
    <div className="bgPaginaPrincipalAdmin d-flex flex-row">
         <Navbar
        collapseOnSelect="false"
        expand="xl"
        className="bgNavAdmin"
        variant="dark"
      >
        <Container className="d-flex flex-column justify-content-start align-content-start">
          <Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="fw-bold"
            />
            Panel Administrador{" "}
          </Navbar.Brand>

          <Navbar.Collapse>
            <Nav className="me-auto d-flex flex-column">
              <Nav.Link className="text-white text-decoration-none">
                <Link
                  to="/login/admin/panelusuarios"
                  className="text-decoration-none text-white"
                >
                  Panel de Usuarios
                </Link>
              </Nav.Link>

              <Nav.Link>
                {" "}
                <Link
                  className="text-white text-decoration-none fw-bold "
                  to="/login/admin/nuevo-usuario"
                >
                  Agregar Usuario
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/login/admin/tablaprospectos"
                  className="text-decoration-none text-white"
                >
                  Panel Cotizaciones
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/login/admin/tablaconsultas"
                  className="text-decoration-none text-white  "
                >
                  Panel Consultas
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="container bgPaginaPrincipalAdmin  p-5 ">
        <h2 className="text-center mb-3 fw-bold textAzul bgtitulosad pb-3">Agregar Usuario</h2>
        <Form onSubmit={handleSubmit} className="bgFormAdmin m-auto p-4">
          <Form.Group className="my-3 text-white">
            <Form.Label>Nombre y Apellido</Form.Label>
            <Form.Control
              type="text"
              placeholder="Rafa"
              onChange={(e) => setNombre(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3 text-white">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="rafa@ocs.com.ar"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3 text-white" controlId="formBasicPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ingrese una contraseña"
              onChange={(e) => setContrasena(e.target.value)}
            />
          </Form.Group>

          <Button variant="success" type="submit">
            Agregar
          </Button>
        </Form>
        {error === true ? (
          <Alert variant="danger" className="mb-5">
            Debe completar todos los campos
          </Alert>
        ) : null}
      </div>
    </div>
  );
};

export default FormularioUsuariosNuevo;
