import React from "react";
import "./principal.css";
import { Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

const PrincipalNuestrasOficinas = () => {
  AOS.init();
  return (
    <div
      className="container my-5 pt-5 d-flex justify-content-center fuenteMontserrat"
      id="oficinas"
    >
      <Row>
        <Col xs={2} md={1} className="me-auto d-flex justify-content-start" >
          <div className="bordeSeccionNuestrasO"></div>
        </Col>

        <Col xs={10} md={5}>
          <h3 className="fwMontserrat colorFuenteAzul">Atención Presencial</h3>
          <h4>Centro de Atención a Clientes</h4>
          <h5>Mendoza</h5>
          <ul>
            <li className="fuenteMontserrat">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="iconNO"
                fill="currentColor"
              >
                <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
              </svg>
              <span className="fwMontserrat ms-2 fuenteMontserrat">
                Dirección
              </span>{" "}
              <br />
              Pedro Molina 501 - Ciudad Mendoza
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="iconNO"
                fill="currentColor"
              >
                <path d="M256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512zM232 256C232 264 236 271.5 242.7 275.1L338.7 339.1C349.7 347.3 364.6 344.3 371.1 333.3C379.3 322.3 376.3 307.4 365.3 300L280 243.2V120C280 106.7 269.3 96 255.1 96C242.7 96 231.1 106.7 231.1 120L232 256z" />
              </svg>
              <span className="fwMontserrat ms-2">Horario</span> <br />
              Lunes a Viernes de 08:30 a 17 hs
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="iconNO"
                fill="currentColor"
              >
                <path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z" />
              </svg>
              <span className="fwMontserrat ms-2">Email</span> <br />
              contacto@ocs.com.ar
            </li>
          </ul>
        </Col>
        <Col xs={10} md={5} className="ms-auto">
          <h4 className="pt-3">Oficinas Comerciales</h4>
          <h5>Villa Nueva</h5>
          <ul>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="iconNO"
                fill="currentColor"
              >
                <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
              </svg>
              <span className="fwMontserrat ms-2">Dirección</span> <br />
              Sarmiento 1155 Local 103 - Villa Nueva Mendoza
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="iconNO"
                fill="currentColor"
              >
                <path d="M256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512zM232 256C232 264 236 271.5 242.7 275.1L338.7 339.1C349.7 347.3 364.6 344.3 371.1 333.3C379.3 322.3 376.3 307.4 365.3 300L280 243.2V120C280 106.7 269.3 96 255.1 96C242.7 96 231.1 106.7 231.1 120L232 256z" />
              </svg>
              <span className="fwMontserrat ms-2">Horario</span> <br />
              Lunes a Viernes de 09 a 16:30 hs <br /> Sabados de 09:30 a 13 hs
            </li>
          </ul>
          <h5 className="pt-3">Rodeo de la Cruz</h5>
          <ul>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="iconNO"
                fill="currentColor"
              >
                <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
              </svg>
              <span className="fwMontserrat ms-2">Dirección</span> <br />
              Bandera de los Andes 6658 Local 9 - Rodeo de la Cruz - Mendoza
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="iconNO"
                fill="currentColor"
              >
                <path d="M256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512zM232 256C232 264 236 271.5 242.7 275.1L338.7 339.1C349.7 347.3 364.6 344.3 371.1 333.3C379.3 322.3 376.3 307.4 365.3 300L280 243.2V120C280 106.7 269.3 96 255.1 96C242.7 96 231.1 106.7 231.1 120L232 256z" />
              </svg>
              <span className="fwMontserrat ms-2">Horario</span> <br />
              Lunes a Viernes de 09 a 16:30 hs <br /> Sabados de 09:30 a 13 hs
            </li>
          </ul>
        </Col>
        <Col xs={2} md={1} className=" d-flex justify-content-start align-content-end">
          <div className="bordeSeccionNuestrasO m-auto"></div>
        </Col>
      </Row>
    </div>
  );
};

export default PrincipalNuestrasOficinas;
