import React from "react";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import LogoTriunfo from "../../../img/logoEmpresas/triunfo.png";

const TablaMoto = () => {
  return (
    <div className="fuenteMontserrat">
      <Row>
        <Col md={12} lg={5}>
          <h2 className="fw-bold fuenteAzul">Coberturas</h2>
          <h3 className="fs-4 fwMontserrat mt-3">Responsabilidad Civil</h3>
          <p>
            Cubre los reclamos que el asegurado (y/o conductor), pueda recibir
            como consecuencia de los daños causados a terceras personas o a sus
            bienes, hasta el límite de cobertura que figura en la póliza y en
            los términos de la misma.
          </p>
          <h3 className="fs-4 fwMontserrat mt-3">Moto Full</h3>
          <p>Brinda cobertura de Robo Total e Incendio Total. one</p>
          <h3 className="fs-4 fwMontserrat mt-3">Moto Platinum</h3>
          <p>
            Ideada para quienes buscan una mayor protección para sus vehículos,
            la línea Moto Platinum contempla Destrucción Total a causa de
            accidente y Daños por Granizo.
          </p>
          <h3 className="fs-4 fwMontserrat mt-3">Moto Alta Gama</h3>
          <p>
            Dirigida a motos con un valor superior a $ 350.000, esta línea
            ofrece una de las coberturas más completas del mercado, incluyendo
            Daños parciales por Incendio y Robo de ruedas.
          </p>
        </Col>

        <Col md={12} lg={7}>
          <Table striped bordered hover responsive className="fsTablaAuto">
            <thead className="text-center">
              <tr>
                <th>Coberturas</th>
                <th className="text-center">Responsabilidad Civil</th>
                <th className="text-center">Moto Full</th>
                <th className="text-center">Moto Platinum</th>
                <th className="text-center">Moto Alta Gama</th>
              </tr>
            </thead>
            <tbody className="text-center">
              <tr>
                <td>Compañia</td>
                <td>
                  <img
                    src={LogoTriunfo}
                    alt="Triunfo Seguros"
                    className="logoTabla"
                  />
                </td>
                <td>
                  <img
                    src={LogoTriunfo}
                    alt="Triunfo Seguros"
                    className="logoTabla"
                  />
                </td>
                <td>
                  <img
                    src={LogoTriunfo}
                    alt="Triunfo Seguros"
                    className="logoTabla"
                  />
                </td>
                <td>
                  <img
                    src={LogoTriunfo}
                    alt="Triunfo Seguros"
                    className="logoTabla"
                  />
                </td>
              </tr>
              <tr>
                <td>Responsabilidad Civil</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>
              <tr>
                <td>Robo y/o Hurto Total</td>
                <td></td>
                <td>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>
              <tr>
                <td>Robo y/o Hurto Parcial</td>
                <td></td>
                <td></td>
                <td></td>

                <td>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>

              <tr>
                <td>Incendio Total</td>
                <td></td>
                <td>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>
              <tr>
                <td>Incendio Parcial</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>
              <tr>
                <td>Daño Total por Accidente</td>
                <td></td>
                <td></td>
                <td>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>
              <tr>
                <td>Daños por Granizo</td>
                <td></td>
                <td></td>

                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
              </tr>

              <tr>
                <td>Extensión de la cobertura a paises limitrofes</td>

                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />{" "}
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>
              <tr>
                <td>Antigüedad</td>

                <td>Sin Límite</td>
                <td>15 años</td>
                <td>5 años</td>
                <td>5 años</td>
              </tr>

              <tr>
                <td></td>
                <td className="text-center">
                <Button>
                    <a
                      href="/productos/motocicleta/#cotizadorMoto"
                      className="text-white text-decoration-none"
                    >
                      Cotizar
                    </a>
                  </Button>
                </td>
                <td className="text-center">
                <Button>
                    <a
                      href="/productos/motocicleta/#cotizadorMoto"
                      className="text-white text-decoration-none"
                    >
                      Cotizar
                    </a>
                  </Button>
                </td>
                <td className="text-center">
                <Button>
                    <a
                      href="/productos/motocicleta/#cotizadorMoto"
                      className="text-white text-decoration-none"
                    >
                      Cotizar
                    </a>
                  </Button>
                </td>
                <td className="text-center">
                <Button>
                    <a
                      href="/productos/motocicleta/#cotizadorMoto"
                      className="text-white text-decoration-none"
                    >
                      Cotizar
                    </a>
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default TablaMoto;
