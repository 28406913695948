import React from "react";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import LogoTriunfo from "../../../img/logoEmpresas/triunfo.png";

const TablaCobBicicletas = () => {
  return (
    <section>
      <Row>
        <Col md={6}>
          <h2 className="fw-bold fuenteAzul">Coberturas</h2>
          <h3 className="fs-4 fwMontserrat mt-3">Plata</h3>
          <p>
            Cubre la bicicleta o Monopatín por Robo Total e Incendio Total e
            incluye una cobertura de Vida para el conductor de la bicicleta.
          </p>
          <h3 className="fs-4 fwMontserrat mt-3">Oro</h3>
          <p>
            Cubre la bicicleta o Monopatín por Robo Total, Incendio Total,
            Destrucción Total de la bicicleta por accidente, responsabilidad
            civil hechos privados y una cobertura de Vida para el conductor de
            la bicicleta.
          </p>

          <h3 className="fs-4 fwMontserrat mt-3">Importante</h3>
          <ul>
            <li>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChek me-2 fs-5"
              />
              Se cubre un evento anual o hasta el límite de suma asegurada.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Solamente un equipo por póliza de seguro.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Ámbito de cobertura: mundial.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Franquicia: 20%
            </li>
          </ul>
        </Col>

        <Col md={6}>
          <div className="fuenteMontserrat">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Coberturas</th>
                  <th className="text-center">Plata</th>
                  <th className="text-center">Oro</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Aseguralo en </td>
                  <td>
                    <img
                      src={LogoTriunfo}
                      alt="Triunfo Seguros"
                      className="logoTabla"
                    />
                  </td>
                  <td>
                    <img
                      src={LogoTriunfo}
                      alt="Triunfo Seguros"
                      className="logoTabla"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Robo Total</td>
                  <td>
                    <FontAwesomeIcon icon={faCheck} className="ms-5 coloChek" />
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faCheck} className="ms-5 coloChek" />
                  </td>
                </tr>
                <tr>
                  <td>Incendio Total</td>
                  <td>
                    <FontAwesomeIcon icon={faCheck} className="ms-5 coloChek" />
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faCheck} className="ms-5 coloChek" />
                  </td>
                </tr>
                <tr>
                  <td>Responsabilidad Civil</td>
                  <td></td>
                  <td>
                    <FontAwesomeIcon icon={faCheck} className="ms-5 coloChek" />
                  </td>
                </tr>
                <tr>
                  <td>Destrucción Total por Accidente</td>
                  <td></td>
                  <td>
                    <FontAwesomeIcon icon={faCheck} className="ms-5 coloChek" />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="text-center">
                  <Button>
                <a
                  href="/productos/bicicleta/#cotizadorBici"
                  className="text-white text-decoration-none"
                >
                  Cotizar
                </a>
              </Button>
                  </td>
                  <td className="text-center">
                  <Button>
                <a
                  href="/productos/bicicleta/#cotizadorBici"
                  className="text-white text-decoration-none"
                >
                  Cotizar
                </a>
              </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default TablaCobBicicletas;
