import React, { useEffect, useState } from "react";
import AutoFoto from "../../../img/fotosProductos/autoWeb.jpg";
import AutoFotoCel from "../../../img/fotosProductos/autoInstagram.jpg";
import AutoFotoTablet from "../../../img/fotosProductos/autoFacebook.jpg";
import { Card, Row, Col } from "react-bootstrap";
import "./productos.css";
import CotizadorAutomotor from "../Cotizador/CotizadorAutomotor";
import TablaCobAutomotor from "./TablaCobAutomotor";
// import coberturaMundial from "../../../img/iconosCaracteristicasProductos/coberturaMundial.png";
import contratacionOnLine from "../../../img/iconosCaracteristicasProductos/contratacionOnLine.png";
import laMejorPropuesta from "../../../img/iconosCaracteristicasProductos/laMejorPropuesta.png";
import pagosElectronicos from "../../../img/iconosCaracteristicasProductos/pagosElectronicos.png";

const Automotor = (props) => {
  const [anos, setAnos] = useState([]);
  const URLprov = process.env.REACT_APP_API_URL_ANO;

  const consultarAdminse = async () => {
    try {
      const respuesta = await fetch(URLprov);
      const datos = await respuesta.json();
      setAnos(datos);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    consultarAdminse();
  }, []);

  console.log(anos);

  return (
    <section id="inicio">
      <div>
        <Card className="bg-dark border text-white">
          <Card.Img
            src={AutoFoto}
            alt="Imagen producto seguro automotor"
            className="w-100 wImgDos d-none d-md-block d-lg-block d-xl-block d-xxl-block "
          />
          <Card.Img
            src={AutoFotoTablet}
            alt="Imagen producto seguro automotor"
            className="w-100 wImgDos d-none d-sm-block d-md-none"
          />
          <Card.Img
            src={AutoFotoCel}
            alt="Imagen producto seguro automotor"
            className="w-100 wImgDos d-xs-block d-sm-none"
          />
          <div className="poligonoProductoCuatro bg-white d-none d-lg-block d-xl-block d-xxl-block"></div>
          <Card.ImgOverlay className="ms-auto d-block d-lg-none bgTextSm">
            <Card.Title className="tituloProductoDosMd fuenteMontserrat fw-bold text-center">
              Seguro Automotor
            </Card.Title>
            <Card.Text className="subtituloProductoDosMd fuenteMontserrat w-75 m-auto text-center">
              Pedi tu cotización online y disfruta de una 10% de descuento en el
              costo de tu seguro.
            </Card.Text>
          </Card.ImgOverlay>
          <Card.ImgOverlay className="poligonoProductoTres ms-auto d-none d-lg-block d-xl-block d-xxl-block">
            <Card.Title className="tituloProductoDos fuenteMontserrat fw-bold">
              Seguro{" "}
              <span>
                <br />
              </span>{" "}
              Automotor
            </Card.Title>
            <Card.Text className="subtituloProductoDos fuenteMontserrat">
              Pedi tu cotización online y disfruta de una 10% de descuento en el
              costo de tu seguro.
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
      <section className="fuenteMontserrat mt-5 container-fluid">
        <h2 className="text-center fw-bold fuenteAzul">Características</h2>
        <Row className="justify-content-evenly my-3 py-3 w-100 ">
          {/* <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={coberturaMundial} alt="" className="wIconosCaract" />
                <p className="fw-bold text-center mt-3 fuenteIconosCaract">
                  Cobertura Mundial
                </p>
              </div>
            </Col> */}
          <Col md={2} lg={2} xl={2}>
            <div className="text-center">
              <img
                src={contratacionOnLine}
                alt="icono contratacion online"
                className="wIconosCaract"
              />
              <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                Contratación Online
              </p>
            </div>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <div className="text-center">
              <img
                src={laMejorPropuesta}
                alt="icono la mejor propuesta"
                className="wIconosCaract"
              />
              <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                La mejor propuesta
              </p>
            </div>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <div className="text-center">
              <img
                src={pagosElectronicos}
                alt="icono medios de pago electronicos"
                className="wIconosCaract"
              />
              <p className=" fw-bold  text-center mt-3 fuenteIconosCaract ">
                Pago Electrónicos
              </p>
            </div>
          </Col>
        </Row>
      </section>
      <section className="container mt-5">
        <TablaCobAutomotor></TablaCobAutomotor>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={8}>
            <CotizadorAutomotor
              consultarProspectos={props.consultarProspectos}
              anos={anos}
            ></CotizadorAutomotor>
          </Col>
        </Row>
      </section>
    </section>
  );
};

export default Automotor;
