import React from "react";
import Viaje from "../../../img/fotosProductos/viajeWeb.jpg";
import ViajeCel from "../../../img/fotosProductos/viajeInstagram.jpg";
import ViajeTablet from "../../../img/fotosProductos/viajeFacebook.jpg";
import { Card, Row, Col } from "react-bootstrap";
import CotizadorViajes from "../Cotizador/CotizadorViajes";
import "./productos.css";
import TablaCobViajero from "./TablaCobViajero";
import coberturaMundial from "../../../img/iconosCaracteristicasProductos/coberturaMundial.png";
import contratacionOnLine from "../../../img/iconosCaracteristicasProductos/contratacionOnLine.png";
import laMejorPropuesta from "../../../img/iconosCaracteristicasProductos/laMejorPropuesta.png";
import pagosElectronicos from "../../../img/iconosCaracteristicasProductos/pagosElectronicos.png";

const AsistenciaAlViajero = (props) => {
  return (
    <section>
      <div>
        <Card className="bg-dark border text-white">
        <Card.Img
            src={Viaje}
            alt="Imagen producto asistencia al viajero - covid"
            className="w-100 wImgDos d-none d-md-block d-lg-block d-xl-block d-xxl-block "
          />
          <Card.Img
            src={ViajeTablet}
            alt="Imagen producto asistencia al viajero - covid"
            className="w-100 wImgDos d-none d-sm-block d-md-none"
          />
          <Card.Img
            src={ViajeCel}
            alt="Imagen producto asistencia al viajero - covid"
            className="w-100 wImgDos d-xs-block d-sm-none"
          />
          <div className="poligonoProductoDos bg-white d-none d-lg-block d-xl-block d-xxl-block"></div>
          <Card.ImgOverlay className="ms-auto d-block d-lg-none bgTextSm">
            <Card.Title className="tituloProductoMd fuenteMontserrat fw-bold text-center">
              Asistencia al Viajero
            </Card.Title>
            <Card.Text className="subtituloProductoMd fuenteMontserrat text-center">
              Viaja seguro por Argentina y el resto del mundo.
            </Card.Text>
          </Card.ImgOverlay>
          <Card.ImgOverlay className="poligonoProducto ms-auto d-none d-lg-block d-xl-block d-xxl-block">
            <Card.Title className="tituloProducto fuenteMontserrat fw-bold">
              Asistencia al Viajero
            </Card.Title>
            <Card.Text className="subtituloProducto fuenteMontserrat">
              Viaja seguro por Argentina y el resto del mundo.
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
      <section className="fuenteMontserrat mt-5 container-fluid">
          <h2 className="text-center fw-bold fuenteAzul">Características</h2>
          <Row className="d-flex justify-content-evenly my-3 py-3 ">
          <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={coberturaMundial} alt="" className="wIconosCaract" />
                <p className="fw-bold text-center mt-3 fuenteIconosCaract">
                  Cobertura Mundial
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img
                  src={contratacionOnLine}
                  alt="icono contratacion online"
                  className="wIconosCaract"
                />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                  Contratación Online
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={laMejorPropuesta} alt="icono la mejor propuesta" className="wIconosCaract" />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                  La mejor propuesta
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={pagosElectronicos} alt="icono medios de pago electronico" className="wIconosCaract" />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract ">
                  Pago Electrónicos
                </p>
              </div>
            </Col>
          </Row>
        </section>

      <section className="container mt-5">
        
        <TablaCobViajero></TablaCobViajero>
<Row className="justify-content-center">
<Col xs={12} sm={12} md={12} lg={8} >
        <CotizadorViajes
          consultarProspectos={props.consultarProspectos}
        ></CotizadorViajes>
</Col>
</Row>
      </section>
    </section>
  );
};

export default AsistenciaAlViajero;
