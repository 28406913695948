import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./components/common/Navigation";
import Footer from "./components/common/Fotter";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Principal from "./components/pages/Principal";
import PrincipalNavLogin from "./components/pages/PrincipalNavLogin";
import Login from "./components/pages/AdminUsers/Login";
import PrincipalAdmin from "./components/pages/AdminUsers/PrincipalAdmin";
import { useEffect, useState } from "react";
import FormularioUsuariosNuevo from "./components/pages/AdminUsers/FormularioUsuariosNuevo";
import FormularioUsuariosEditar from "./components/pages/AdminUsers/FormularioUsuariosEditar";
import PrincipalUsers from "./components/pages/Users/PrincipalUsers";
import PrincipalProductos from "./components/pages/PrincipalProductos";
import Error404 from "./components/pages/Error404";
import AccidentesPersonales from "./components/pages/Productos/AccidentesPersonales";
import AsistenciaAlViajero from "./components/pages/Productos/AsistenciaAlViajero";
import Automotor from "./components/pages/Productos/Automotor";
import Bicicleta from "./components/pages/Productos/Bicicleta";
import Celular from "./components/pages/Productos/Celular";
import Hogar from "./components/pages/Productos/Hogar";
import Motocicleta from "./components/pages/Productos/Motocicleta";
import Praxis from "./components/pages/Productos/Praxis";
import TablaProspectos from "./components/pages/AdminUsers/TablaProspectos";
import BienvenidaAdmin from "./components/pages/AdminUsers/BienvenidaAdmin";
import TablaConsultas from "./components/pages/AdminUsers/TablaConsultas";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [usuarios, setUsuarios] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [prospecto, setProspecto] = useState([]);
  const [loginCtr, setLoginCtr] = useState(false);
  const [loginCtrAd, setLoginCtrAd] = useState(false);
  const [consultaContacto, setConsultaContacto] = useState([]);

  const URL = process.env.REACT_APP_API_URL_USER;
  const URL_a = process.env.REACT_APP_API_URL_ADMIN;
  const URL_p = process.env.REACT_APP_API_URL_PROSPECTOS;
  const URL_c = process.env.REACT_APP_API_URL_CONTACTO;

  useEffect(() => {
    consultarUser();
    consultarAdmin();
    consultarInicio();
    consultarProspectos();
    consultarContacto();
  }, []);

  const consultarContacto = async () => {
    try {
      const respuesta = await fetch(URL_c);
      const dato = await respuesta.json();
      setConsultaContacto(dato);
    } catch (err) {
      console.log(err);
    }
  };
  const consultarProspectos = async () => {
    try {
      const respuesta = await fetch(URL_p);
      const coti = await respuesta.json();
      setProspecto(coti);
    } catch (err) {
      console.log(err);
    }
  };

  const consultarUser = async () => {
    try {
      const respuesta = await fetch(URL);
      const datos = await respuesta.json();
      setUsuarios(datos);
    } catch (err) {
      console.log(err);
    }
  };

  const consultarAdmin = async () => {
    try {
      const respuesta = await fetch(URL_a);
      const datos = await respuesta.json();
      setAdmins(datos);
    } catch (err) {
      console.log(err);
    }
  };
  const consultarInicio = () => {
    if (loginCtr === true) {
      setLoginCtr(true);
    } else {
      setLoginCtr(false);
    }
    if (loginCtrAd === true) {
      setLoginCtrAd(true);
    } else {
      setLoginCtrAd(false);
    }
  };

  return (
    <Router>
      <ScrollToTop/>
      <PrincipalNavLogin
        loginCtrAd={loginCtrAd}
        setLoginCtrAd={setLoginCtrAd}
        loginCtr={loginCtr}
        setLoginCtr={setLoginCtr}
      />
      <Navigation
        loginCtrAd={loginCtrAd}
        setLoginCtrAd={setLoginCtrAd}
        loginCtr={loginCtr}
        setLoginCtr={setLoginCtr}
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Principal consultarContacto={consultarContacto}></Principal>
          }
        ></Route>
        <Route
          exact
          path="/productos"
          element={<PrincipalProductos></PrincipalProductos>}
        ></Route>
        <Route
          exact
          path="/productos/ap"
          element={
            <AccidentesPersonales
              consultarProspectos={consultarProspectos}
            ></AccidentesPersonales>
          }
        ></Route>
        <Route
          exact
          path="/productos/av"
          element={
            <AsistenciaAlViajero
              consultarProspectos={consultarProspectos}
            ></AsistenciaAlViajero>
          }
        ></Route>
        <Route
          exact
          path="/productos/automotor"
          element={
            <Automotor consultarProspectos={consultarProspectos}></Automotor>
          }
        ></Route>
        <Route
          exact
          path="/productos/bicicleta"
          element={
            <Bicicleta consultarProspectos={consultarProspectos}></Bicicleta>
          }
        ></Route>
        <Route
          exact
          path="/productos/celular"
          element={
            <Celular consultarProspectos={consultarProspectos}></Celular>
          }
        ></Route>
        <Route
          exact
          path="/productos/hogar"
          element={<Hogar consultarProspectos={consultarProspectos}></Hogar>}
        ></Route>
        <Route
          exact
          path="/productos/motocicleta"
          element={
            <Motocicleta
              consultarProspectos={consultarProspectos}
            ></Motocicleta>
          }
        ></Route>
        <Route
          exact
          path="/productos/praxis"
          element={<Praxis consultarProspectos={consultarProspectos}></Praxis>}
        ></Route>
        <Route
          exact
          path="/login"
          element={
            <Login
              loginCtrAd={loginCtrAd}
              setLoginCtrAd={setLoginCtrAd}
              loginCtr={loginCtr}
              setLoginCtr={setLoginCtr}
              admins={admins}
              usuarios={usuarios}
            ></Login>
          }
        ></Route>
        <Route
          exact
          path="/login/admin"
          element={
            loginCtrAd ? (
              <BienvenidaAdmin></BienvenidaAdmin>
            ) : (
              <Navigate to="/"></Navigate>
            )
          }
        ></Route>
        <Route
          exact
          path="/login/admin/panelusuarios"
          element={
            loginCtrAd ? (
              <PrincipalAdmin
                loginCtrAd={loginCtrAd}
                setLoginCtrAd={setLoginCtrAd}
                loginCtr={loginCtr}
                setLoginCtr={setLoginCtr}
                consultarUser={consultarUser}
                consultarInicio={consultarInicio}
                admins={admins}
                usuarios={usuarios}
              ></PrincipalAdmin>
            ) : (
              <Navigate to="/"></Navigate>
            )
          }
        ></Route>
        <Route
          exact
          path="/login/admin/tablaprospectos"
          element={
            loginCtrAd ? (
              <TablaProspectos
                loginCtrAd={loginCtrAd}
                setLoginCtrAd={setLoginCtrAd}
                consultarInicio={consultarInicio}
                consultarUser={consultarUser}
                consultarProspectos={consultarProspectos}
                consulta
                admins={admins}
                prospecto={prospecto}
              ></TablaProspectos>
            ) : (
              <Navigate to="/"></Navigate>
            )
          }
        ></Route>
        <Route
          exact
          path="/login/admin/tablaconsultas"
          element={
            loginCtrAd ? (
              <TablaConsultas
                loginCtrAd={loginCtrAd}
                setLoginCtrAd={setLoginCtrAd}
                consultarInicio={consultarInicio}
                consultarUser={consultarUser}
                consultarContacto={consultarContacto}
                admins={admins}
                consultaContacto={consultaContacto}
              ></TablaConsultas>
            ) : (
              <Navigate to="/"></Navigate>
            )
          }
        ></Route>
        <Route
          exact
          path="/login/admin/nuevo-usuario"
          element={
            <FormularioUsuariosNuevo
              loginCtrAd={loginCtrAd}
              setLoginCtrAd={setLoginCtrAd}
              loginCtr={loginCtr}
              setLoginCtr={setLoginCtr}
              consultarUser={consultarUser}
              admins={admins}
              usuarios={usuarios}
            ></FormularioUsuariosNuevo>
          }
        ></Route>
        <Route
          exact
          path="/login/admin/editar-usuario/:id"
          element={
            <FormularioUsuariosEditar
              loginCtrAd={loginCtrAd}
              setLoginCtrAd={setLoginCtrAd}
              loginCtr={loginCtr}
              setLoginCtr={setLoginCtr}
              consultarUser={consultarUser}
              admins={admins}
              usuarios={usuarios}
            ></FormularioUsuariosEditar>
          }
        ></Route>
        <Route
          exact
          path="/login/users"
          element={
            loginCtr ? (
              <PrincipalUsers
                loginCtrAd={loginCtrAd}
                setLoginCtrAd={setLoginCtrAd}
                loginCtr={loginCtr}
                setLoginCtr={setLoginCtr}
                consultarUser={consultarUser}
                consultarInicio={consultarInicio}
                admins={admins}
                usuarios={usuarios}
              ></PrincipalUsers>
            ) : (
              <Navigate to="/"></Navigate>
            )
          }
        ></Route>
        <Route exact path="/error404" element={<Error404></Error404>}></Route>
      </Routes>
      <Footer></Footer>
    </Router>
  );
}

export default App;
