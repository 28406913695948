import React from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const DescripcionProductoPraxis = () => {
  return (
    <div className="fuenteMontserrat mt-5">
      <Row>
        <Col md={6}>
          <h2 className="fw-bold fuenteAzul">Coberturas</h2>
          <h3 className="fs-4 fwMontserrat mt-3">Praxis Médica</h3>
          <p>
          El asegurador se obliga a mantener indemne al asegurado, por cuanto deba a un paciente o derecho habiente del mismo en razón de la responsabilidad civil contractual o extracontractual en que incurra como consecuencia del ejercicio como profesional de la salud. Actuando habitualmente en la especialidad indicada en las condiciones particulares de póliza, producto de acciones u omisiones ocurridas dentro de la vigencia de la misma
          </p>
          

        </Col>
        <Col md={6}>
          <h3 className="fs-4 fwMontserrat mt-3">Adicionales</h3>
          <ul>
            <li>
              <FontAwesomeIcon icon={faAngleRight} className="coloChek me-2 fs-5" />
              Defensa penal            </li>
            <li> <FontAwesomeIcon icon={faAngleRight} className="coloChekRight me-2 fs-5" /> Asistencia jurídica las 24 hs. </li>
            <li> <FontAwesomeIcon icon={faAngleRight} className="coloChekRight me-2 fs-5" /> Ámbito de cobertura: nacional.</li>
            <li> <FontAwesomeIcon icon={faAngleRight} className="coloChekRight me-2 fs-5" /> Cobertura de todas las especialidades</li>
       
          </ul>
         
          <h3 className="fs-4 fwMontserrat ">Requisitos de contratación:</h3>
          <p></p>
          <ul>
            <li> <FontAwesomeIcon icon={faAngleRight} className="coloChekRight me-2 fs-5" />Poseer credencial o certificado de Matrícula.</li>
            <li> <FontAwesomeIcon icon={faAngleRight} className="coloChekRight me-2 fs-5" />
              No tener otras coberturas vigentes similares
            </li>
           
          </ul>
        </Col>

        <Col md={6}></Col>
      </Row>
    </div>
  );
};

export default DescripcionProductoPraxis;
