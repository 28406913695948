import React, { useState } from "react";
import "./principal.css";
import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import {
  validarNombre,
  campoRequerido,
  validarCorreo,
} from "../Helpers/helpers";
import emailjs from "@emailjs/browser";
import { init } from "@emailjs/browser";
init("goeW6mKbl5HzsTzEV");

const PrincipalContacto = (props) => {
  const [nombre, setNombre] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [whatsapp, setWhatsapp] = useState(" ");
  const [mensaje, setMensaje] = useState(" ");
  const [errorContact, setErrorContact] = useState(false);
  const [result, setResult] = useState(false);
  const URL = process.env.REACT_APP_API_URL_CONTACTO;

  const Result = () => {
    return (
      <p className="text-white fw-bold ps-5 ms-5 pb-3">
        ¡Su mensaje fue enviado con éxito!
      </p>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      validarNombre(nombre) &&
      validarCorreo(email) &&
      campoRequerido(mensaje)
    ) {
      const datos = {
        nombre: nombre,
        email: email,
        whatsapp: whatsapp,
        mensaje: mensaje,
      };
      setErrorContact(false);
      emailjs
        .send("service_bi0bu0v", "template_sgtqy4m", datos, "goeW6mKbl5HzsTzEV")
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
      e.target.reset();
      setResult(true);
      try {
        const parametros = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datos),
        };
        const respuesta = await fetch(URL, parametros);
        if (respuesta.status === 201) {
        } else {
        }
        e.target.reset();
        props.consultarContacto();
      } catch (error) {
        console.log(error);
      }
    } else {
      setErrorContact(true);
      console.log("mensaje de error");
    }
  };

  return (
    <section className="py-5 mt-5 fuenteMontserrat" id="contacto">
      <Container fluid className="seccionContacto">
        <Row>
          <Col md={0} lg={2}></Col>
          <Col>
            <Form
              className="pt-5 container-fluid col-md-6 pb-5 wFormContacto  "
              onSubmit={handleSubmit}
            >
              <h1 className="d-flex align-items-center mt-5 fw-bold text-white fs-1 fuenteMontserrat">
                Contáctanos
              </h1>
              <p className="text-white">
                Envianos tu mensaje y te responderemos a la brevedad
              </p>
              <Form.Group className=" flex-column align-items mb-3">
                <Form.Control
                  type="text"
                  placeholder="Tu nombre"
                  className="mContacto"
                  maxLength="38"
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Tu email"
                  className="mContacto"
                  maxLength="40"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="WhatsApp"
                  className="mContacto"
                  maxLength="15"
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Tu Mensaje"
                  minLength="10"
                  maxLength="200"
                  className="mContacto"
                  onChange={(e) => setMensaje(e.target.value)}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Enviar Mensaje
              </Button>
            </Form>
            {errorContact === true ? (
              <Alert variant="danger" className="mb-5">
                Debe completar todos los campos
              </Alert>
            ) : null}

            {result ? <Result /> : null}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PrincipalContacto;
