import React, { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { campoRequerido } from "../../Helpers/helpers";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import { init } from "@emailjs/browser";
init("goeW6mKbl5HzsTzEV");

const CotizadorCelular = (props) => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [marcaCel, setMarcaCel] = useState("");
  const [modeloCel, setModeloCel] = useState("");
  const [valorCel, setValorCel] = useState("");
  const [validated, setValidated] = useState(false);
  const [cotizador] = useState("Cotizador Celular");
  const URL = process.env.REACT_APP_API_URL_PROSPECTOS;
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (
      campoRequerido(nombre) &&
      campoRequerido(email) &&
      campoRequerido(telefono) &&
      campoRequerido(marcaCel) &&
      campoRequerido(valorCel) &&
      campoRequerido(modeloCel)
    ) {
      const nuevoProspecto = {
        nombre: nombre,
        email: email,
        telefono: telefono,
        marcaCel: marcaCel,
        modeloCel: modeloCel,
        valorCel: valorCel,
        cotizador: cotizador,
      };
      try {
        const parametros = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(nuevoProspecto),
        };
        const respuesta = await fetch(URL, parametros);
        console.log(respuesta);
        if (respuesta.status === 201) {
          Swal.fire(
            "Pedido de cotización enviado",
            "Responderemos su pedido a la brevedad",
            "success"
          );
          emailjs
          .send(
            "service_bi0bu0v",
            "template_7019ryx",
            nuevoProspecto,
            "goeW6mKbl5HzsTzEV"
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
        } else {
          Swal.fire(
            "Pedido de cotización no enviado",
            "Existen problemas con el servidor",
            "error"
          );
        }
        e.target.reset();
        props.consultarProspectos();
        setValidated(false);
      } catch (error) {
        console.log(error);
      }
    } else {
       console.log("mensaje de error");
    }
  };

  return (
    <div className="container my-5" id="formularioCel">
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="p-5 m-auto  border bgFormProd2 text-white responsive fuenteMontserrat"
          >
            <h2 className="text-center fwMontserrat mb-4 ">
              Cotiza tu celular
            </h2>

            <Row>
              <Col xs={12} sm={12} md={6} >
                <Form.Group className="mb-3" controlId="Marca Celular">
                  <Form.Label className="fwMontserrat">
                    Marca del Celular
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    pattern="^[a-zA-ZÀ-ÿ\s0-9]{1,20}$"
                    maxLength="20"
                    onChange={(e) => setMarcaCel(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid" className="text-white">
                    Debe ingresar la marca de su celular
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} >
                <Form.Group className="mb-3" controlId="Modelo Celular">
                  <Form.Label className="fwMontserrat">Modelo</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    pattern="^[a-zA-ZÀ-ÿ\s0-9]{1,20}$"
                    maxLength="20"
                    onChange={(e) => setModeloCel(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid" className="text-white">
                    Debe ingresar el modelo de su celular
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col xs={12} sm={12} md={6} >
                <Form.Group className="mb-3" controlId="Valor Celular">
                  <Form.Label className="fwMontserrat">Valor</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    required
                    onChange={(e) => setValorCel(e.target.value)}
                  >
                    <option></option>
                    <option value="75000">$75.000</option>
                    <option value="100000">$100.000</option>
                    <option value="125000">$125.000</option>
                    <option value="150000">$150.000</option>
                    <option value="200000">$200.000</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid" className="text-white">
                    Debe seleccionar un año
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} >
                <Form.Group className="mb-3" controlId="Valor Celular">
                  <Form.Label className="fwMontserrat">Antigüedad</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    required
                    onChange={(e) => setValorCel(e.target.value)}
                  >
                    <option></option>
                    <option value="1">1 año</option>
                    <option value="2">2 años</option>
                    <option value="3">3 años</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid" className="text-white">
                    Debe seleccionar un año
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="fwMontserrat">
                    Nombre y Apellido
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength="25"
                    pattern="^[a-zA-ZÀ-ÿ\s]{4,25}$"
                    onChange={(e) => setNombre(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid" className="text-white">
                    Debe ingresar su Nombre y Apellido
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12} md={5}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="fwMontserrat">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="wWhatsapp me-2"
                      fill="currentColor"
                      viewBox="0 0 448 512"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                    Teléfono
                  </Form.Label>
                  <Form.Control
                    type="text"
                    minLength="6"
                    maxLength="15"
                    pattern="^[0-9]{1,15}$"
                    required
                    onChange={(e) => setTelefono(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid" className="text-white">
                    Debe ingresar su teléfono de contacto
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12}  md={7}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="fwMontserrat">Email</Form.Label>
                  <Form.Control
                    type="email"
                    pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid" className="text-white">
                    Debe ingresar su email
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Button variant="success fwMontserrat" type="submit">
              Pedir Cotización
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CotizadorCelular;
