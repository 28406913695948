const campoRequerido = (input) => {
  if (input.trim() === "") {
    return false;
  } else {
    return true;
  }
};

const validarCorreo = (dato) => {
  let patron =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (patron.test(dato)) {
    return true;
  } else {
    return false;
  }
};
const validarNombre = (dato) => {
  let patron = /^[a-zA-ZÀ-ÿ\s]{1,30}$/;
  if (patron.test(dato)) {
    return true;
  } else {
    return false;
  }
};
const validarTelefono = (dato) => {
  let patron = /^[0-9]{1,13}$/;
  if (patron.test(dato)) {
    return true;
  } else {
    return false;
  }
};
const validarAno = (input) => {
  let patron = /^[0-9]{1,8}$/;
  if (patron.test(input)) {
    return true;
  } else {
    return false;
  }
};
const validarValor = (input) => {
  let patron = /^[0-9]{1,8}$/;
  if (patron.test(input)) {
    return true;
  } else {
    return false;
  }
};

export {
  campoRequerido,
  validarCorreo,
  validarNombre,
  validarTelefono,
  validarAno,
  validarValor,
};
