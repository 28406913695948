import React from "react";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import LogoMa from "../../../img/logoEmpresas/mercantil.png";

const DescripcionProductoCel = () => {
  return (
    <div className="fuenteMontserrat mt-5">
      <Row>
        <Col md={6}>
          <h2 className="fw-bold fuenteAzul">Coberturas</h2>
          <h3 className="fs-4 fwMontserrat mt-3">Robo</h3>
          <p>
            Se cubre la pérdida del celular o smartphone de propiedad y uso
            personal del asegurado causada exclusivamente por el robo del equipo
            (no hurto o extravío).
          </p>
          <h3 className="fs-4 fwMontserrat mt-3">Todo Riesgo</h3>
          <p>
            Se cubren los daños o la pérdida del celular o smartphone de
            propiedad y uso personal del asegurado causados exclusivamente por
            el daño accidental o robo del equipo (no hurto o extravío).
          </p>

          <h3 className="fs-4 fwMontserrat mt-3">Importante</h3>
          <ul>
            <li>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChek me-2 fs-5"
              />
              Se cubre un evento anual o hasta el límite de suma asegurada.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Solamente un equipo por póliza de seguro.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Ámbito de cobertura: mundial.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Franquicia: 20%
            </li>
          </ul>
        </Col>
        <Col md={6}>
          <Table striped bordered hover className="mt-5 text-center">
            <thead>
              <tr>
                <th>Coberturas</th>
                <th className="text-center">Robo</th>
                <th className="text-center">Todo Riesgo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Aseguralo en</td>
                <td>
                  <img
                    src={LogoMa}
                    alt="Mercantil Andina"
                    className="logoTabla"
                  />
                </td>
                <td>
                  <img
                    src={LogoMa}
                    alt="Mercantil Andina"
                    className="logoTabla"
                  />
                </td>
              </tr>
              <tr>
                <td>Robo Total</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="ms-5 coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="ms-5 coloChek" />
                </td>
              </tr>
              <tr>
                <td>Daño Accidental</td>
                <td></td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="ms-5 coloChek" />
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="text-center">
                  <Button href="#formularioCel">Cotizar</Button>
                </td>
                <td className="text-center">
                  <Button href="#formularioCel">Cotizar</Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <h3 className="fs-4 fwMontserrat">Requisitos de contratación:</h3>
          <p></p>
          <ul>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />
              Ser mayor de 18 años.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />
              El asegurado debe ser titular del bien o poseer línea telefónica
              del mismo a su nombre (en caso de ser familiar demostrar
              parentesco).
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />
              Bien asegurado con hasta 3 años de antigüedad al momento de la
              contratación.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />
              Envío de fotos y datos del modelo del equipo.
            </li>
          </ul>
        </Col>

        <Col md={6}></Col>
      </Row>
    </div>
  );
};

export default DescripcionProductoCel;
