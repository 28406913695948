import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../../img/logoOCS.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import DatosOcsPDF from "./DatosOcsPDF";

const ConsultaAdminPDF = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "cotizacion",
  });
  return (
    <>
      <div
        ref={componentRef}
        style={{ width: "100%", height: window.innerHeigh }}
      >
        <div className="container m-5 fuenteMontserrat">
          <img src={Logo} alt="logo de OCS" className="w-25 mt-3" />
          <h3 className="text-center my-3  fs-2 py-2 fwMontserrat">
            Detalle de la consulta
          </h3>
          <p>
            <span className="fw-bold">Nombre y Apellido: </span>{" "}
            {props.consulta.nombre} <br />
            <span className="fw-bold">Email: </span> {props.consulta.email}{" "}
            <br />
            <span className="fw-bold"> Whatsapp: </span>{" "}
            {props.consulta.whatsapp} <br />
            <span className="fw-bold"> Consulta: </span>{" "}
            {props.consulta.mensaje} <br />
          </p>
        </div>
        <DatosOcsPDF></DatosOcsPDF>
      </div>
      <button onClick={handlePrint} className="py-5 ms-4">
        <FontAwesomeIcon icon={faPrint} className="fs-2 text-info" /> IMPRIMIR CONSULTA
      </button>
    </>
  );
};

export default ConsultaAdminPDF;
