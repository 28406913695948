import React from "react";
import "./principal.css";
import { Row, Col, Card } from "react-bootstrap";
import logoAuto from "../../img/iconosProductos/autoSF.png";
import logoMoto from "../../img/iconosProductos/motoSF.png";
import logoBici from "../../img/iconosProductos/bicicletaSF.png";
import logoViaje from "../../img/iconosProductos/viajeSF.png";
import logoHogar from "../../img/iconosProductos/hogarSF.png";
import logoCelular from "../../img/iconosProductos/celularSF.png";
import logoAp from "../../img/iconosProductos/apSF.png";
import logoPraxis from "../../img/iconosProductos/praxisSF.png";
import fondoAzul from "../../img/fondoAzul.png";
import fondoAzulTablet from "../../img/fondosFormularios/fondoAzulTablet.png";
import fondoAzulCelular from "../../img/fondosFormularios/fondoAzulCelular.png";
import { Link } from "react-router-dom";

const PrincipalProductos = () => {
  return (
    <section id="productos">
      <Card className="text-white">
        <img
          src={fondoAzul}
          alt="seccion de productos"
          className="hfondoAzul d-none d-lg-block d-xl-block d-xxl-block"
        />
        <img
          src={fondoAzulTablet}
          alt="seccion de productos"
          className="hfondoAzul d-none d-md-block d-lg-none"
        />
        <img
          src={fondoAzulCelular}
          alt="seccion de productos"
          className="hfondoAzul d-xs-block d-sm-block d-md-none"
        />
        <Card.ImgOverlay>
          <Card.Title className="text-white text-center my-3 fs-1 fw-bold fuenteMontserrat">
            Coberturas
          </Card.Title>

          <Card.Text></Card.Text>
          <div className="d-flex flex-column align-items-center ">
            <div className="d-flex justify-content-start wGruposIconos ">
              <Row>
                <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                  <div className="contenedorProducto">
                    <div className="figure my-4">
                      <img
                        src={logoAuto}
                        alt="seguro de automovil"
                        className="wIconoPruducto "
                      />{" "}
                      <div className="capa">
                        <Link
                          to="/productos/automotor"
                          className="text-decoration-none"
                        >
                          <h3>Auto</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                  <div className="contenedorProducto">
                    <div className="my-4">
                      <img
                        src={logoMoto}
                        alt="seguro de motocicletas"
                        className="wIconoPruducto "
                      />
                      <div className="capa">
                        <Link
                          to="/productos/motocicleta"
                          className="text-decoration-none"
                        >
                          <h3>Moto</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                  <div className="contenedorProducto ">
                    <div className="my-4">
                      <img
                        src={logoBici}
                        alt="seguro de bicicleta"
                        className="wIconoPruducto "
                      />
                      <div className="capa">
                        <Link
                          to="/productos/bicicleta"
                          className="text-decoration-none"
                        >
                          <h3>Bicicleta</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                  <div className="contenedorProducto">
                    <div className="my-4">
                      <img
                        src={logoViaje}
                        alt="seguro para viajero - covid"
                        className="wIconoPruducto "
                      />
                      <div className="capa">
                        <Link
                          to="/productos/av"
                          className="text-decoration-none"
                        >
                          <h3>Asistencia al viajero</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                  <div className="contenedorProducto">
                    <div className="my-4">
                      <img
                        src={logoHogar}
                        alt="seguro de hogar"
                        className="wIconoPruducto "
                      />
                      <div className="capa">
                        <Link
                          className="text-secondary text-decoration-none w-100"
                          to="/productos/hogar"
                        >
                          <h3> Hogar </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                  <div className="contenedorProducto">
                    <div className="my-4">
                      <img
                        src={logoCelular}
                        alt="seguro de celular"
                        className="wIconoPruducto "
                      />
                      <div className="capa">
                        <Link
                          className="text-secondary text-decoration-none w-100"
                          to="/productos/celular"
                        >
                          <h3> Celular </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                  <div className="contenedorProducto">
                    <div className="my-4">
                      <img
                        src={logoAp}
                        alt="seguro de accidentes personales"
                        className="wIconoPruducto "
                      />
                      <div className="capa">
                        <Link
                          className="text-secondary text-decoration-none w-100"
                          to="/productos/ap"
                        >
                          <h3> Accidentes Personales </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                  <div className="contenedorProducto">
                    <div className="my-4">
                      <img
                        src={logoPraxis}
                        alt="seguro de praxis medica"
                        className="wIconoPruducto "
                      />
                      <div className="capa">
                        <Link
                          className="text-secondary text-decoration-none w-100"
                          to="/productos/praxis"
                        >
                          <h3> Praxis Médica </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Card.ImgOverlay>
      </Card>
    </section>
  );
};

export default PrincipalProductos;
