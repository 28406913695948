import React from "react";
import Error40 from "../../img/error404.png";
import "./principal.css";

const Error404 = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={Error40} alt="imagen error 404" className="w-75" />
      <article className="d-flex justify-content-center align-items-center">
        <div className="bordeError404"></div>
        <h2 className="text-secondary fw-bold ms-3 me-3">PAGE NOT FOUND</h2>
        <div className="bordeError404"></div>
      </article>
    </div>
  );
};

export default Error404;
