import React from "react";
import "./principal.css";
import { Link } from "react-router-dom";

const PrincipalNavLogin = (props) => {
  const salirLogin = () => {
    props.setLoginCtr(false);
    props.setLoginCtrAd(false);
  };
  return (
    <div className="bgNavLogin d-flex justify-content-end align-items-center">
      <div id="container">
        {props.loginCtr === false ? (
          <button className="learn-more   ">
            <Link to="/login">
              <span className="circle" aria-hidden="true">
                <span className="d-flex justify-content-initial ms-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="userIcon mt-3 "
                    fill="currentColor"
                    width="20"
                  >
                    <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z" />
                  </svg>
                </span>
                <span className="button-text ms-5 ps-3 fwMontserrat fwMontserrat ">PRODUCTORES</span>
              </span>
            </Link>
          </button>
        ) : (
          
          <button className="learn-more   ">
            <Link
              to="/"
              id="botonIngresar"
              className="w-auto p-2 colorFuenteGris fsuscribe"
              onClick={() => {
                salirLogin();
              }}
            >
              <span className="circle bg-danger" aria-hidden="true">
                <span className="d-flex justify-content-initial ms-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="userIcon mt-3 bg-danger "
                    fill="currentColor"
                    width="20"
                  >
                    <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z" />
                  </svg>
                </span>
                <span className="button-text ms-5 ps-3 ">CERRAR SESION</span>
              </span>
            </Link>
          </button>
          
        )}
      </div>
    </div>
  );
};

export default PrincipalNavLogin;
