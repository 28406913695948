import React from "react";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import LogoMa from "../../../img/logoEmpresas/mercantil.png";

const TablaCobViajero = () => {
  return (
    <div className="fuenteMontserrat">
      <Row>
        <Col md={6}>
          <h2 className="fw-bold fuenteAzul">Coberturas</h2>

          <p>
            Cobertura las 24 hs de asistencia en viaje y seguro de accidentes
            personales con cobertura integral de COVID-19.
          </p>

          <h3 className="fs-4 fwMontserrat mt-3">Incluye</h3>
          <ul>
            <li>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChek me-2 fs-5"
              />
              Gastos de estadía por resposo forzoso
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Traslado sanitario
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Seguro de vida por accidente
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Traslado y estancia de un familiar
            </li>
          </ul>
        </Col>

        <Col md={6}></Col>
      </Row>

      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <Table striped bordered hover responsive className="text-center">
            <thead>
              <tr>
                <th>Coberturas</th>
                <th>Argentina</th>
                <th>Agentina Plus</th>
                <th>Mundo Plata</th>
                <th>Mundo Oro</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Aseguralo en</td>
                <td>
                  <img
                    src={LogoMa}
                    alt="Mercantil Andina"
                    className="logoTabla"
                  />
                </td>
                <td>
                  <img
                    src={LogoMa}
                    alt="Mercantil Andina"
                    className="logoTabla"
                  />
                </td>
                <td>
                  <img
                    src={LogoMa}
                    alt="Mercantil Andina"
                    className="logoTabla"
                  />
                </td>
                <td>
                  <img
                    src={LogoMa}
                    alt="Mercantil Andina"
                    className="logoTabla"
                  />
                </td>
              </tr>
              <tr>
                <td>Cobertura en Argentina</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
              </tr>
              <tr>
                <td>Cobertura en el Resto del Mundo</td>
                <td></td>
                <td></td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
              </tr>
              <tr>
                <td>Cobertura accidentes personales</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
              </tr>
              <tr>
                <td>Cobertura integral ante COVID-19</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
              </tr>

              <tr>
                <td>Asistencia al viajero</td>

                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>
              <tr>
                <td>Asistencia médica por accidente y/o enfermedad</td>
                <td>hasta ARS 400.000</td>
                <td>hasta USD 15.000</td>
                <td>hasta USD 35.000</td>
                <td>hasta USD 60.000</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-center">
                <Button>
                <a
                  href="/productos/av/#cotizadorViaje"
                  className="text-white text-decoration-none"
                >
                  Cotizar
                </a>
              </Button>
                </td>
                <td className="text-center">
                <Button>
                <a
                  href="/productos/av/#cotizadorViaje"
                  className="text-white text-decoration-none"
                >
                  Cotizar
                </a>
              </Button>
                </td>
                <td className="text-center">
                <Button>
                <a
                  href="/productos/av/#cotizadorViaje"
                  className="text-white text-decoration-none"
                >
                  Cotizar
                </a>
              </Button>
                </td>
                <td className="text-center">
                <Button>
                <a
                  href="/productos/av/#cotizadorViaje"
                  className="text-white text-decoration-none"
                >
                  Cotizar
                </a>
              </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default TablaCobViajero;
