import React from "react";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const TablaCobAutomotor = () => {
  return (
    <div className="fuenteMontserrat">
      <Row>
        <Col md={5}>
          <h2 className="fw-bold fuenteAzul">Coberturas</h2>
          <h3 className="fs-4 fwMontserrat mt-3">Responsabilidad Civil</h3>
          <p>
            Cubre los reclamos que el asegurado (y/o conductor), pueda recibir
            como consecuencia de los daños causados a terceras personas o a sus
            bienes, hasta el límite de cobertura que figura en la póliza y en
            los términos de la misma.
          </p>
          <h3 className="fs-4 fwMontserrat mt-3">Terceros Básico</h3>
          <p>
            Brinda cobertura de daños al casco del vehículo en caso de Incendio
            Total, Robo y/o Hurto Total y Daño por Accidente Total.
          </p>
          <h3 className="fs-4 fwMontserrat mt-3">Terceros Completo</h3>
          <p>
            Brinda coberturas de daños al casco del vehículo en caso de
            Incendio, Robo y/o Hurto (Total y Parcial) y Daño por Accidente
            Total, más adicionales.
          </p>
          <h3 className="fs-4 fwMontserrat mt-3">Todo Riesgo</h3>
          <p>
            Además de la cobertura de Terceros completo, incluye Daño por
            Accidente Parcial, con Franquicia.
          </p>
        </Col>

        <Col md={7}>
          <Table striped bordered hover responsive className="fsTablaAuto">
            <thead className="text-center">
              <tr>
                <th>Coberturas</th>
                <th className="text-center">Responsabilidad Civil</th>
                <th className="text-center">Terceros Básico</th>
                <th className="text-center">Terceros Completo</th>
                <th className="text-center">Todo Riesgo</th>
              </tr>
            </thead>
            <tbody className="text-center">
              <tr>
                <td>Responsabilidad Civil</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>
              <tr>
                <td>Robo y/o Hurto</td>
                <td></td>
                <td>Total</td>
                <td>Total y Parcial</td>
                <td>Total y Parcial</td>
              </tr>

              <tr>
                <td>Incendio</td>
                <td></td>
                <td>Total</td>
                <td>Total y Parcial</td>
                <td>Total y Parcial</td>
              </tr>
              <tr>
                <td>Daño por Accidente</td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>Total y Parcial</td>
              </tr>
              <tr>
                <td>Rotura de Cristales</td>
                <td></td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
              </tr>
              <tr>
                <td>Rotura de Cerraduras</td>
                <td></td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className="coloChek" />
                </td>
              </tr>
              <tr>
                <td>Daños por Granizo</td>
                <td></td>
                <td></td>
                <td>Con tope</td>
                <td>Sin Límite</td>
              </tr>
              <tr>
                <td>Asistencia al vehículo</td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>
              <tr>
                <td>Extensión de la cobertura a paises limitrofes</td>

                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />{" "}
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheck} className=" coloChek" />
                </td>
              </tr>

              <tr>
                <td></td>
                <td className="text-center">
                  <Button>
                    <a
                      href="/productos/automotor/#cotizadorAuto"
                      className="text-white text-decoration-none"
                    >
                      Cotizar
                    </a>
                  </Button>
                </td>
                <td className="text-center">
                  <Button>
                    <a
                      href="/productos/automotor/#cotizadorAuto"
                      className="text-white text-decoration-none"
                    >
                      Cotizar
                    </a>
                  </Button>
                </td>
                <td className="text-center">
                  <Button>
                    <a
                      href="/productos/automotor/#cotizadorAuto"
                      className="text-white text-decoration-none"
                    >
                      Cotizar
                    </a>
                  </Button>
                </td>
                <td className="text-center">
                  <Button>
                    <a
                      href="/productos/automotor/#cotizadorAuto"
                      className="text-white text-decoration-none"
                    >
                      Cotizar
                    </a>
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default TablaCobAutomotor;
