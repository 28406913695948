import React from "react";
import { Carousel, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImagenA from "../../img/imagenAuto.jpg";
import ImagenB from "../../img/imagenMoto.jpg";
import ImagenC from "../../img/imagenBicicleta.jpg";
import "./principal.css";

const PrincipalSlider = () => {
  return (
    <div>
      <Carousel fade controls="false ">
        <Carousel.Item controls="false">
          <img
            className="d-block w-100 hImagen "
            src={ImagenA}
            alt="First slide"
          />

          <Carousel.Caption
            fade
            className="bgSlider px-5 pt-5 fuenteMontserrat"
          >
            <p className="sliderTextA fadeIN fuenteMontserrat">
              La cobertura mas conveniente del mercado
            </p>
            <h3 className="sliderTextB fuenteMontserrat text-uppercase fadeIN fw-bold">
              Asegura tu vehículo
            </h3>
            <div className="sliderBarra"></div>

            <Button className="sliderButton fadeIN fuenteMontserrat">
              <a
                href="/productos/automotor"
                className="text-white text-decoration-none"
              >
                VER MAS
              </a>
            </Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 hImagen"
            src={ImagenB}
            alt="Second slide"
          />

          <Carousel.Caption className="bgSlider px-5 pt-5 fuenteMontserrat">
            <p className="sliderTextA fadeIN fuenteMontserrat ">
              La cobertura mas conveniente del mercado
            </p>
            <h3 className="sliderTextB text-uppercase fadeIN fuenteMontserrat fw-bold">
              Asegura tu motocicleta
            </h3>
            <div className="sliderBarra"></div>
            <Button className="sliderButton fadeIN fuenteMontserrat">
              <a
                href="/productos/motocicleta"
                className="text-white text-decoration-none"
              >
                VER MAS
              </a>
            </Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 hImagen"
            src={ImagenC}
            alt="Third slide"
          />

          <Carousel.Caption className="bgSlider px-5 pt-5">
            <p className="sliderTextA fadeIN fuenteMontserrat">
              La cobertura mas conveniente del mercado
            </p>
            <h3 className="sliderTextB text-uppercase fadeIN fuenteMontserrat fw-bold">
              Asegura tu bicicleta
            </h3>
            <div className="sliderBarra"></div>
            <Button className="sliderButton fadeIN fuenteMontserrat">
              <a
                href="/productos/bicicleta"
                className="text-white text-decoration-none"
              >
                VER MAS
              </a>
            </Button>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default PrincipalSlider;
