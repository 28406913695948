import React from 'react';

const DatosOcsPDF = () => {
    return (
        <div className="bgAzul w-100 h-25 text-center text-white">
            www.ocs.com.ar
        </div>
    );
};

export default DatosOcsPDF;