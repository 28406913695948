import React from "react";
import HogarFoto from "../../../img/fotosProductos/hogarWeb.jpg";
import HogarFotoCel from "../../../img/fotosProductos/hogarInstagram.jpg";
import HogarFotoTablet from "../../../img/fotosProductos/hogarFacebook.jpg";
import { Card, Row, Col } from "react-bootstrap";
import "./productos.css";
import CotizadorHogar from "../Cotizador/CotizadorHogar";
import DescripcionProductoHogar from "./DescripcionProductoHogar";
import TablaCobHogar from "./TablaCobHogar";
// import coberturaMundial from "../../../img/iconosCaracteristicasProductos/coberturaMundial.png";
import contratacionOnLine from "../../../img/iconosCaracteristicasProductos/contratacionOnLine.png";
import laMejorPropuesta from "../../../img/iconosCaracteristicasProductos/laMejorPropuesta.png";
import pagosElectronicos from "../../../img/iconosCaracteristicasProductos/pagosElectronicos.png";

const Hogar = (props) => {
  return (
    <section>
      <div>
        <Card className="bg-dark border text-white">
          <Card.Img
            src={HogarFoto}
            alt="Imagen producto hogar"
            className="w-100 wImgDos d-none d-md-block d-lg-block d-xl-block d-xxl-block "
            id="inicioHogar"
          />
          <Card.Img
            src={HogarFotoTablet}
            alt="Imagen producto hogar"
            className="w-100 wImgDos d-none d-sm-block d-md-none"
          />
          <Card.Img
            src={HogarFotoCel}
            alt="Imagen producto hogar"
            className="w-100 wImgDos d-xs-block d-sm-none"
          />
          <div className="poligonoProductoCuatro bg-white d-none d-lg-block d-xl-block d-xxl-block"></div>
          <Card.ImgOverlay className="ms-auto d-block d-lg-none bgTextSm">
            <Card.Title className="tituloProductoDosMd fuenteMontserrat fw-bold text-center">
              Seguro Hogar
            </Card.Title>
            <Card.Text className="subtituloProductoDosMd fuenteMontserrat w-75 m-auto text-center">
              Pedi tu cotización online y disfruta de una 10% de descuento en el
              costo de tu seguro.
            </Card.Text>
          </Card.ImgOverlay>
          <Card.ImgOverlay className="poligonoProductoTres ms-auto d-none d-lg-block d-xl-block d-xxl-block">
            <Card.Title className="tituloProductoDos fuenteMontserrat fw-bold">
              Seguro Hogar
            </Card.Title>
            <Card.Text className="subtituloProductoDos fuenteMontserrat">
              Pedi tu cotización online y disfruta de una 10% de descuento en el
              costo de tu seguro.
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
      <section className="container mt-5">
        <section className="fuenteMontserrat mt-5">
          <h2 className="text-center fw-bold fuenteAzul">Características</h2>
          <Row className="d-flex justify-content-evenly my-3 py-3 ">
          {/* <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={coberturaMundial} alt="" className="wIconosCaract" />
                <p className="fw-bold text-center mt-3 fuenteIconosCaract">
                  Cobertura Mundial
                </p>
              </div>
            </Col> */}
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img
                  src={contratacionOnLine}
                  alt="icono contratacion online"
                  className="wIconosCaract"
                />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                  Contratación Online
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={laMejorPropuesta} alt="icono la mejor propuesta" className="wIconosCaract" />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                  La mejor propuesta
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={pagosElectronicos} alt="icono de medios de pago electronicos" className="wIconosCaract" />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract ">
                  Pago Electrónicos
                </p>
              </div>
            </Col>
          </Row>
        </section>
        <Row className="justify-content-center">
          <Col md={12}>
            <DescripcionProductoHogar></DescripcionProductoHogar>
          </Col>
          <Col md={12}>
            <TablaCobHogar></TablaCobHogar>
          </Col>
         
          <Col xs={12} sm={12} md={10} lg={10} className="d-flex justify-content-center">
            <CotizadorHogar
              consultarProspectos={props.consultarProspectos}
            ></CotizadorHogar>
            <Col></Col>
          </Col>
        </Row>
      </section>
    </section>
  );
};

export default Hogar;
