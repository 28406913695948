import React from "react";
import PRAXIS from "../../../img/fotosProductos/praxisWeb.jpg";
import PRAXISCel from "../../../img/fotosProductos/praxisInstagram.jpg";
import PRAXISTablet from "../../../img/fotosProductos/praxisFacebook.jpg";
import { Card, Row, Col } from "react-bootstrap";
import CotizadorPraxis from "../Cotizador/CotizadorPraxis";
import DescripcionProductoPraxis from "./DescripcionProductoPraxis";
// import coberturaMundial from "../../../img/iconosCaracteristicasProductos/coberturaMundial.png";
import contratacionOnLine from "../../../img/iconosCaracteristicasProductos/contratacionOnLine.png";
import laMejorPropuesta from "../../../img/iconosCaracteristicasProductos/laMejorPropuesta.png";
import pagosElectronicos from "../../../img/iconosCaracteristicasProductos/pagosElectronicos.png";

const Praxis = (props) => {
  return (
    <section>
      <div>
        <Card className="bg-dark border text-white">
        <Card.Img
            src={PRAXIS}
            alt="Imagen producto praxis medica"
            className="w-100 wImgDos d-none d-md-block d-lg-block d-xl-block d-xxl-block "
          />
          <Card.Img
            src={PRAXISTablet}
            alt="Imagen producto praxis medica"
            className="w-100 wImgDos d-none d-sm-block d-md-none"
          />
          <Card.Img
            src={PRAXISCel}
            alt="Imagen producto praxis medica"
            className="w-100 wImgDos d-xs-block d-sm-none"
          />
          <div className="poligonoProductoCuatro bg-white d-none d-lg-block d-xl-block d-xxl-block"></div>
          <Card.ImgOverlay className="ms-auto d-block d-lg-none bgTextSm">
            <Card.Title className="tituloProductoDosMd fuenteMontserrat fw-bold text-center">
              Praxis Médica
            </Card.Title>
            <Card.Text className="subtituloProductoDosMd fuenteMontserrat w-75 m-auto text-center">
              Seguro exclusivo para profesionales de la salud.{" "}
              <span>
                <br /> Pedi hoy tu cotización online. 
              </span>
            </Card.Text>
          </Card.ImgOverlay>
          <Card.ImgOverlay className="poligonoProductoTres ms-auto d-none d-lg-block d-xl-block d-xxl-block">
            <Card.Title className="tituloProductoDos fuenteMontserrat fw-bold">
              Praxis Médica
            </Card.Title>
            <Card.Text className="subtituloProductoDos fuenteMontserrat">
              Seguro exclusivo para profesionales de la salud.{" "}
              <span>
                <br /> Pedi hoy tu cotización online. 
              </span>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
      <section className="fuenteMontserrat mt-5 container-fluid">
          <h2 className="text-center fw-bold fuenteAzul">Características</h2>
          <Row className="d-flex justify-content-evenly my-3 py-3 ">
          {/* <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={coberturaMundial} alt="" className="wIconosCaract" />
                <p className="fw-bold text-center mt-3 fuenteIconosCaract">
                  Cobertura Mundial
                </p>
              </div>
            </Col> */}
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img
                  src={contratacionOnLine}
                  alt="icono contratacion online"
                  className="wIconosCaract"
                />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                  Contratación Online
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={laMejorPropuesta} alt="icono la mejor propuesta" className="wIconosCaract" />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                  La mejor propuesta
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={pagosElectronicos} alt="icono medios de pago electronicos" className="wIconosCaract" />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract ">
                  Pago Electrónicos
                </p>
              </div>
            </Col>
          </Row>
        </section>



      <section className="container mt-5">
        <Row>
          <Col md={12}>
            <DescripcionProductoPraxis></DescripcionProductoPraxis>
          </Col>
          <Col xs={0} sm={0} md={2}></Col>
          <Col xs={12} sm={12} md={8} className="d-flex justify-content-center">
            <CotizadorPraxis
              consultarProspectos={props.consultarProspectos}
            ></CotizadorPraxis>
            <Col></Col>
          </Col>
        </Row>
      </section>
    </section>
  );
};

export default Praxis;
