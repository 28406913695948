import React from "react";
import CelularFoto from "../../../img/fotosProductos/celularWeb.jpg";
import CelularFotoCel from "../../../img/fotosProductos/celularInstagram.jpg";
import CelularFotoTablet from "../../../img/fotosProductos/celularFacebook.jpg";
import { Card, Row, Col } from "react-bootstrap";
import "./productos.css";
import CotizadorCelular from "../Cotizador/CotizadorCelular";
import DescripcionProductoCel from "./DescripcionProductoCel";
import coberturaMundial from "../../../img/iconosCaracteristicasProductos/coberturaMundial.png";
import contratacionOnLine from "../../../img/iconosCaracteristicasProductos/contratacionOnLine.png";
import laMejorPropuesta from "../../../img/iconosCaracteristicasProductos/laMejorPropuesta.png";
import pagosElectronicos from "../../../img/iconosCaracteristicasProductos/pagosElectronicos.png";

const Celular = (props) => {
  return (
    <section>
      <div>
        <Card className="bg-dark border text-white">
        <Card.Img
            src={CelularFoto}
            alt="Imagen producto seguro celular"
            className="w-100 wImgDos d-none d-md-block d-lg-block d-xl-block d-xxl-block "
          />
          <Card.Img
            src={CelularFotoTablet}
            alt="Imagen producto seguro celular"
            className="w-100 wImgDos d-none d-sm-block d-md-none"
          />
          <Card.Img
            src={CelularFotoCel}
            alt="Imagen producto seguro celular"
            className="w-100 wImgDos d-xs-block d-sm-none"
          />
          <div className="poligonoProductoCuatro bg-white d-none d-lg-block d-xl-block d-xxl-block"></div>
          <Card.ImgOverlay className="ms-auto d-block d-lg-none bgTextSm">
            <Card.Title className="tituloProductoDosMd fuenteMontserrat fw-bold text-center">
              Seguro Celular
            </Card.Title>
            <Card.Text className="subtituloProductoDosMd fuenteMontserrat w-75 m-auto text-center">
              Protegelo contra Robo o Daño.{" "}
              <span>
                <br />
                Pedí hoy tu cotización online.
              </span>
            </Card.Text>
          </Card.ImgOverlay>
          <Card.ImgOverlay className="poligonoProductoTres ms-auto d-none d-lg-block d-xl-block d-xxl-block">
            <Card.Title className="tituloProductoDos fuenteMontserrat fw-bold">
              Seguro Celular
            </Card.Title>
            <Card.Text className="subtituloProductoDos fuenteMontserrat">
              Protegelo contra Robo o Daño.{" "}
              <span>
                <br />
                Pedí hoy tu cotización online.
              </span>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </div>
      <section className="container mt-5">
        <section className="fuenteMontserrat">
          <h2 className="text-center fw-bold fuenteAzul">Características</h2>
          <Row className="d-flex justify-content-evenly my-3 py-3 ">
          <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={coberturaMundial} alt="icono cobertura mundial" className="wIconosCaract" />
                <p className="fw-bold text-center mt-3 fuenteIconosCaract">
                  Cobertura Mundial
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img
                  src={contratacionOnLine}
                  alt="icono contratacion online"
                  className="wIconosCaract"
                />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                  Contratación Online
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={laMejorPropuesta} alt="icono sobre la mejor propuesta" className="wIconosCaract" />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract">
                  La mejor propuesta
                </p>
              </div>
            </Col>
            <Col md= {2} lg={2} xl={2}>
              <div className="text-center">
                <img src={pagosElectronicos} alt="iconos de medios de pago electronico" className="wIconosCaract" />
                <p className=" fw-bold  text-center mt-3 fuenteIconosCaract ">
                  Pago Electrónicos
                </p>
              </div>
            </Col>
          </Row>
        </section>
        <DescripcionProductoCel></DescripcionProductoCel>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={10}>
        <CotizadorCelular
          consultarProspectos={props.consultarProspectos}
        ></CotizadorCelular>
           </Col>
        </Row>
      </section>
    </section>
  );
};

export default Celular;
