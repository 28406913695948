import React from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight } from "@fortawesome/free-solid-svg-icons";

const DescripcionProductoAP = () => {
  return (
    <div className="fuenteMontserrat mt-5">
      <Row>
        <Col md={6}>
          <h2 className="fw-bold fuenteAzul">Coberturas</h2>
          <p>
            El seguro de accidentes personales brinda cobertura por los riesgos
            de muerte o invalidez total o parcial permanente, como consecuencia
            de un accidente que pueda sufrir la persona asegurada segun la
            modalidad declarada.
          </p>

          <h3 className="fs-4 fwMontserrat mt-3">Tipos de Asegurados:</h3>
          <p></p>
          <ul>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />
              Trabajadores autónomos o en relacion de dependencia.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />
              Personal de Salud
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />
              Deliverys
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />
              Estudiantes
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />
              Deportistas
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />
              Otras actividades
            </li>
          </ul>
        </Col>
        <Col md={6}>
          <h3 className="fs-4 fwMontserrat mt-4">Modalidades de cobertura</h3>
          <ul>
            <li>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChek me-2 fs-5"
              />
              Solo por la actividad declarada.
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Las 24 hs.
            </li>
          </ul>

          <h3 className="fs-4 fwMontserrat mt-3">Adionales opcionales</h3>
          <ul>
            <li>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChek me-2 fs-5"
              />
              Cobertura “in itinire”
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Asistencia Médico Farmacéutica
            </li>
            <li>
              {" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                className="coloChekRight me-2 fs-5"
              />{" "}
              Cobertura mundial, las 24 horas del día, los 365 días del año.
            </li>
          </ul>
        </Col>

        <Col md={6}></Col>
      </Row>
    </div>
  );
};

export default DescripcionProductoAP;
