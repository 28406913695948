import React from "react";
import PrincipalSlider from "./PrincipalSlider";
import PrincipalContacto from "./PrincipalContacto";
import PrincipalNuestrasOficinas from "./PrincipalNuestrasOficinas";
import PrincipalProductos from "./PrincipalProductos";
import PrincipalEmpresas from "./PrincipalEmpresas";

const principal = (props) => {
  return (
    <div>
     <PrincipalSlider></PrincipalSlider>
      <PrincipalEmpresas></PrincipalEmpresas>
      <PrincipalProductos></PrincipalProductos> 
      <PrincipalNuestrasOficinas></PrincipalNuestrasOficinas>
      <PrincipalContacto
        consultarContacto={props.consultarContacto}
      ></PrincipalContacto>
    </div>
  );
};

export default principal;
