import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../../img/logoOCS.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import DatosOcsPDF from "./DatosOcsPDF";

const CotizacionAdminPDF = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "cotizacion" + props.prospecto.nombre,
  });
  return (
    <>
      <div
        ref={componentRef}
        style={{ width: "100%", height: window.innerHeigh }}
      >
        <div className="container m-5 fuenteMontserrat">
          <img src={Logo} alt="logo de OCS" className="w-25 mt-3" />
          <h3 className="text-center my-3  fs-2 py-2 fwMontserrat">
            Detalle de la cotización
          </h3>
          <p>
            <span className="fw-bold me-2">Nombre y Apellido: </span>{" "}
            {props.prospecto.nombre} <br />
            <span className="fw-bold me-2">Email: </span>{" "}
            {props.prospecto.email} <br />
            <span className="fw-bold">Teléfono: </span>{" "}
            {props.prospecto.telefono} <br />
            {props.prospecto.cotizador === "Cotizador Bicicleta" ? (
              <div>
                <span className="fw-bold me-2">Marca: </span>
                {props.prospecto.marca}
                <br />
                <span className="fw-bold me-2">Modelo: </span>
                {props.prospecto.modelo}
                <br />
                <span className="fw-bold me-2">Año de fabricacion: </span>
                {props.prospecto.ano}
                <br />
                <span className="fw-bold me-2">Rodado: </span>
                {props.prospecto.rodado}
                <br />
                <span className="fw-bold me-2">Valor: </span>
                {props.prospecto.valor}
                <br />
              </div>
            ) : null}
            {props.prospecto.cotizador === "Cotizador Asistencia al Viajero" ? (
              <div>
                <span className="fw-bold me-2">Destino: </span>
                {props.prospecto.destino}
                <br />
                <span className="fw-bold me-2">Cantidad de días: </span>
                {props.prospecto.dias}
                <br />
                <span className="fw-bold me-2">Mayores de 75 años: </span>
                {props.prospecto.adultosMayores}
                <br />
                <span className="fw-bold me-2">Mayores hasta 75 años: </span>
                {props.prospecto.adultosMenores}
                <br />
                <span className="fw-bold me-2">Menores: </span>
                {props.prospecto.menores}
                <br />
              </div>
            ) : null}
            {props.prospecto.cotizador === "Cotizador Práxis Médica" ? (
              <div>
                <span className="fw-bold me-2">Especialidad: </span>
                {props.prospecto.especialidad}
                <br />
                <span className="fw-bold me-2">N° de matrícula: </span>
                {props.prospecto.matricula}
                <br />
                <span className="fw-bold me-2">Ambito de aplicación</span>
                {props.prospecto.ambito}
                <br />
              </div>
            ) : null}
            {props.prospecto.cotizador === "Cotizador Hogar" ? (
              <div>
                <span className="fw-bold me-2">Tipo de Hogar: </span>
                {props.prospecto.tipoHogar}
                <br />
                <span className="fw-bold me-2">Ubicación: </span>
                {props.prospecto.ubicacion}
                <br />
                <span className="fw-bold me-2 ">Código Postal:</span>
                {props.prospecto.cp}
                <br />
              </div>
            ) : null}
            {props.prospecto.cotizador === "Cotizador Celular" ? (
              <div>
                <span className="fw-bold me-2">Marca del Celular: </span>
                {props.prospecto.marcaCel}
                <br />
                <span className="fw-bold me-2">Modelo: </span>
                {props.prospecto.modeloCel}
                <br />
              </div>
            ) : null}
            {props.prospecto.cotizador === "Cotizador AP" ? (
              <div>
                <span className="fw-bold me-2">Actividad: </span>
                {props.prospecto.actividad}
                <br />
                <span className="fw-bold me-2">Cantidad de personas: </span>
                {props.prospecto.cantidadAP}
                <br />
                <span className="fw-bold me-2">Tipo de cobertura: </span>
                {props.prospecto.tipoCobAP}
                <br />
              </div>
            ) : null}
            <span className="fw-bold me-2">Tipo de cotizador: </span>{" "}
            {props.prospecto.cotizador}
          </p>
        </div>
        <DatosOcsPDF className="m-auto"></DatosOcsPDF>
      </div>
      <button onClick={handlePrint} className="py-5 ms-4">
        <FontAwesomeIcon icon={faPrint} className="fs-2 text-info" /> IMPRIMIR
        COTIZACION
      </button>
    </>
  );
};

export default CotizacionAdminPDF;
