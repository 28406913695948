import React, { useState } from "react";
import "./login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faTrashCan,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import CotizacionAdminPDF from "./VistaPDF.js/CotizacionAdminPDF";

const FormularioProspecto = (props) => {
  const [show, setShow] = useState(false);
  const handlePDFClose = () => setShow(false);
  const handlePDFShow = () => setShow(true);
  const eliminarProspecto = () => {
    Swal.fire({
      title: "¿Esta seguro de eliminar esta cotización?",
      text: "¡Una vez eliminado no se puede recuperar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Borrar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const URL =
            process.env.REACT_APP_API_URL_PROSPECTOS +
            "/" +
            props.prospecto._id;

          const respuesta = await fetch(URL, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          });
          console.log(respuesta);
          if (respuesta.status === 200) {
            Swal.fire(
              "¡Eliminado!",
              "El usuario fue correctamente eliminado.",
              "success"
            );
            props.consultarProspectos();
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  return (
    <tr>
      <td>{props.prospecto._id}</td>
      <td>{props.prospecto.nombre}</td>
      <td>{props.prospecto.email}</td>
      <td>{props.prospecto.cotizador}</td>
      <td>
        <button>
          <FontAwesomeIcon
            icon={faEye}
            className="fs-2 me-2 text-muted"
            onClick={handlePDFShow}
          />
        </button>

        <button onClick={() => eliminarProspecto()}>
          <FontAwesomeIcon
            icon={faTrashCan}
            className="fs-3 me-2 text-danger"
          />
        </button>
        <button>
          <FontAwesomeIcon icon={faEnvelope} className="fs-2 me-2 text-info" />
        </button>
      </td>

      <Modal show={show} onHide={handlePDFClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CotizacionAdminPDF
            prospecto={props.prospecto}
            consultarProspectos={props.consultarProspectos}
          ></CotizacionAdminPDF>
        </Modal.Body>
      </Modal>
    </tr>
  );
};

export default FormularioProspecto;
