import React, { useState } from "react";
import { Navbar, Container, Nav, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Paginacion from "./Paginacion";
import FormularioContacto from "./FormularioContacto";

const TablaConsultas = (props) => {
  //   PAGINACION
  const [paginaActual, setPaginaActual] = useState(1);
  const [prospectoPorPagina] = useState(6);

  const indexUltimoProspecto = paginaActual * prospectoPorPagina;
  const indexPrimerProspecto = indexUltimoProspecto - prospectoPorPagina;
  const currentPost = props.consultaContacto.slice(
    indexPrimerProspecto,
    indexUltimoProspecto
  );

  //   FUNCION PARA CAMBIAR PAGINA
  const paginacion = (pagina) => setPaginaActual(pagina);
  
  return (
    <div className="bgPaginaPrincipalAdmin d-flex flex-row">
      <Navbar
        collapseOnSelect="false"
        expand="xl"
        className="bgNavAdmin"
        variant="dark"
      >
        <Container className="d-flex flex-column justify-content-start align-content-start">
          <Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="fw-bold"
            />
            Panel Administrador{" "}
          </Navbar.Brand>

          <Navbar.Collapse>
            <Nav className="me-auto d-flex flex-column">
              <Nav.Link className="text-white text-decoration-none">
                <Link
                  to="/login/admin/panelusuarios"
                  className="text-decoration-none text-white"
                >
                  Panel de Usuarios
                </Link>
              </Nav.Link>

              <Nav.Link>
                {" "}
                <Link
                  className="text-white text-decoration-none "
                  to="/login/admin/nuevo-usuario"
                >
                  Agregar Usuario
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/login/admin/tablaprospectos"
                  className="text-decoration-none text-white"
                >
                  Panel Cotizaciones
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/login/admin/tablaconsultas"
                  className="text-decoration-none text-white fw-bold "
                >
                  Panel Consultas
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="container m-5">
        <h2 className="text-center mb-3 fw-bold textAzul bgtitulosad ">
          Panel de consulta de contacto
        </h2>
        <Table striped bordered hover responsive className="bgTablaUsuarios">
          <thead>
            <tr className="bgtitulosad">
              <th>#</th>
              <th>Nombre y Apellido</th>
              <th>Email</th>
              <th>Whatsapp</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {currentPost.map((consulta) => (
              <FormularioContacto
                key={consulta.id}
                consulta={consulta}
                consultarContacto={props.consultarContacto}
              ></FormularioContacto>
            ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-center align-items-center m-auto">
          <Paginacion
            usuariosPorPagina={prospectoPorPagina}
            totalUsuarios={props.consultaContacto.length}
            paginacion={paginacion}
            paginaActual={paginaActual}
          ></Paginacion>
        </div>
      </div>
    </div>
  );
};

export default TablaConsultas;
