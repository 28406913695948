import React from "react";
import FormularioLogin from "./FormularioLogin";
import "./login.css";
import { Row, Col } from "react-bootstrap";

const Login = (props) => {
  return (
    <section className="d-flex justify-content-center align-content-center bgPaginaLogin">
      <div className="wFormLogin my-5 pb-5 m-auto ">
        <Row className="justify-content-center">
          
          <Col xs={12} sm={10} md={6}>
            <FormularioLogin
              loginCtrAd={props.loginCtrAd}
              setLoginCtrAd={props.setLoginCtrAd}
              loginCtr={props.loginCtr}
              setLoginCtr={props.setLoginCtr}
              admins={props.admins}
              usuarios={props.usuarios}
              
            ></FormularioLogin>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Login;
