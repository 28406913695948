import React, { useState, Fragment } from "react";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { campoRequerido } from "../../Helpers/helpers";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import { init } from "@emailjs/browser";
init("goeW6mKbl5HzsTzEV");

const CotizadorMoto = (props) => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [provincia, setProvincia] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [ano, setAno] = useState("");
  const [cotizador] = useState("Cotizador Moto");
  const [validated, setValidated] = useState(false);
  const URL = process.env.REACT_APP_API_URL_PROSPECTOS;
  const [cargando, setCargando] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (
      campoRequerido(nombre) &&
      campoRequerido(email) &&
      campoRequerido(telefono) &&
      campoRequerido(marca) &&
      campoRequerido(modelo) &&
      campoRequerido(provincia) &&
      campoRequerido(localidad) &&
      campoRequerido(ano)
    ) {
      const nuevoProspecto = {
        nombre: nombre,
        email: email,
        telefono: telefono,
        provincia: provincia,
        localidad: localidad,
        marca: marca,
        modelo: modelo,
        ano: ano,
        cotizador: cotizador,
      };
      try {
        const parametros = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(nuevoProspecto),
        };
        const respuesta = await fetch(URL, parametros);
        console.log(respuesta);
        if (respuesta.status === 201) {
          Swal.fire(
            "Pedido de cotización enviado",
            "Responderemos su pedido a la brevedad",
            "success"
          );
          emailjs
          .send(
            "service_bi0bu0v",
            "template_7019ryx",
            nuevoProspecto,
            "goeW6mKbl5HzsTzEV"
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
        } else {
          Swal.fire(
            "Pedido de cotización no enviado",
            "Existen problemas con el servidor",
            "error"
          );
        }
        e.target.reset();
        props.consultarProspectos();
        setValidated(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("mensaje de error");
    }
  };

  const mostrarComponente =
    cargando === true ? (
      <Spinner animation="border" size="sm"></Spinner>
    ) : (
      <div className="container my-5">
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="w- p-5 m-auto  border bgFormProd2 text-white responsive fuenteMontserrat"
       id="cotizadorAuto"
       >
          <h2 className="text-center fwMontserrat mb-3">Pedí tu cotización</h2>
          <Form.Group className="mb-3" controlId="Marca Bicicleta">
            <Form.Label>Marca</Form.Label>
            <Form.Control
              type="text"
              required
              maxLength="15"
              onChange={(e) => setMarca(e.target.value)}
            />
            <Form.Control.Feedback type="invalid" className="text-white">
              Debe ingresar la marca de su bicicleta
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="Modelo Bicicleta">
            <Form.Label>Modelo</Form.Label>
            <Form.Control
              type="text"
              required
              maxLength="15"
              onChange={(e) => setModelo(e.target.value)}
            />
            <Form.Control.Feedback type="invalid" className="text-white">
              Debe ingresar el modelo de su bicicleta
            </Form.Control.Feedback>
          </Form.Group>

          <Row className="justify-content-center">
            <Col xs={12}sm={12}>
              <Form.Group className="mb-3" controlId="Año Bicicleta">
                <Form.Label>Año</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  required
                  onChange={(e) => setAno(e.target.value)}
                >
                  <option></option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                  <option value="2017">2017</option>
                  <option value="2016">2016</option>
                  <option value="2015">2015</option>
                  <option value="2014">2014</option>
                  <option value="2013">2013</option>
                  <option value="2012">2012</option>
                  <option value="2011">2011</option>
                  <option value="2010">2010</option>
                  <option value="2009">2009</option>
                  <option value="2008">2008</option>
                  <option value="2007">2007</option>
                  <option value="2006">2006</option>
                  <option value="2005">2005</option>
                  <option value="2004">2004</option>
                  <option value="2003">2003</option>
                  <option value="2002">2002</option>
                  <option value="2001">2001</option>
                  <option value="2000">2000</option>
                  <option value="1999">1999</option>
                  <option value="1998">1998</option>
                  <option value="1997">1997</option>
                  <option value="1996">1996</option>
                  <option value="1995">1995</option>
                  <option value="1994">1994</option>
                  <option value="1993">1993</option>
                  <option value="1992">1992</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid" className="text-white">
                  Debe seleccionar un año
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}sm={12}>
              <Form.Group className="mb-3" controlId="Provincia">
                <Form.Label>Provincia</Form.Label>
                <Form.Control
                  type="text"
                  required
                  maxLength="15"
                  onChange={(e) => setProvincia(e.target.value)}
                />
                <Form.Control.Feedback type="invalid" className="text-white">
                  Debe ingresar la provincia
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}sm={12}>
              <Form.Group className="mb-3" controlId="Localidad">
                <Form.Label>Localidad</Form.Label>
                <Form.Control
                  type="text"
                  required
                  maxLength="15"
                  onChange={(e) => setLocalidad(e.target.value)}
                />
                <Form.Control.Feedback type="invalid" className="text-white">
                  Debe ingresar la localidad
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col xs={12}sm={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Nombre y Apellido</Form.Label>
                <Form.Control
                  type="text"
                  required
                  maxLength="25"
                  onChange={(e) => setNombre(e.target.value)}
                />
                <Form.Control.Feedback type="invalid" className="text-white">
                  Debe ingresar su Nombre y Apellido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12}sm={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid" className="text-white">
                  Debe ingresar su email
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Teléfono de contacto</Form.Label>
            <Form.Control
              type="numer"
              minLength="6"
              maxLength="15"
              required
              onChange={(e) => setTelefono(e.target.value)}
            />
            <Form.Control.Feedback type="invalid" className="text-white">
              Debe ingresar su teléfono de contacto
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="success fwMontserrat" type="submit">
            Pedir Cotización
          </Button>
        </Form>
      </div>
    );

  return <Fragment>{mostrarComponente}</Fragment>;
};

export default CotizadorMoto;
