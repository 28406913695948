import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Form, Button, Alert, Nav, Navbar, Container } from "react-bootstrap";
import { campoRequerido } from "../../Helpers/helpers";
import Swal from "sweetalert2";

const FormularioUsuariosEditar = (props) => {
  const { id } = useParams();
  const [usuario, setUsuario] = useState({});
  const nombreUsuarioRef = useRef("");
  const nombreEmailRef = useRef("");
  const nombreContrasenaRef = useRef("");
  const [error, setError] = useState(false);
  const navegacion = useNavigate();
  const URL = process.env.REACT_APP_API_URL_USER + "/" + id;

  useEffect(async () => {
    try {
      const respuesta = await fetch(URL);
      if (respuesta.status === 200) {
        const dato = await respuesta.json();
        setUsuario(dato);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      campoRequerido(nombreUsuarioRef.current.value) &&
      campoRequerido(nombreEmailRef.current.value) &&
      campoRequerido(nombreContrasenaRef.current.value)
    ) {
      const usuarioModificado = {
        nombre: nombreUsuarioRef.current.value,
        email: nombreEmailRef.current.value,
        contrasena: nombreContrasenaRef.current.value,
      };

      try {
        const respuesta = await fetch(URL, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(usuarioModificado),
        });
        console.log(respuesta);
        if ((await respuesta.status) === 200) {
          Swal.fire(
            "Usuario Modificado",
            "El usuario fue correctamente modificado",
            "success"
          );
          props.consultarUser();
          navegacion("/login/admin");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(true);
    }
  };
  return (
    <div className="bgPaginaPrincipalAdmin d-flex flex-row">
      <Navbar
        collapseOnSelect="false"
        expand="xl"
        className="bgNavAdmin"
        variant="dark"
      >
        <Container className="d-flex flex-column justify-content-start align-content-start">
          <Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="fw-bold"
            />
            Panel Administrador{" "}
          </Navbar.Brand>

          <Navbar.Collapse>
            <Nav className="me-auto d-flex flex-column">
              <Nav.Link className="text-white text-decoration-none">
                <Link
                  to="/login/admin/panelusuarios"
                  className="text-decoration-none text-white"
                >
                  Panel de Usuarios
                </Link>
              </Nav.Link>

              <Nav.Link>
                {" "}
                <Link
                  className="text-white text-decoration-none fw-bold"
                  to="/login/admin/nuevo-usuario"
                >
                  Agregar Usuario
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/login/admin/tablaprospectos"
                  className="text-decoration-none text-white "
                >
                  Panel Cotizaciones
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="container bgPaginaPrincipalAdmin  p-5 ">
        <h2 className="text-center fw-bold  textAzul bgtitulosad pb-3">
          Editar Usuario
        </h2>
        <Form onSubmit={handleSubmit} className="bgFormAdmin p-4 m-auto">
          <Form.Group className="my-3 text-white">
            <Form.Label>Nombre y Apellido</Form.Label>
            <Form.Control
              type="text"
              placeholder="Rafa"
              defaultValue={usuario.nombre}
              ref={nombreUsuarioRef}
            />
          </Form.Group>
          <Form.Group className="mb-3 text-white">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="rafa@ocs.com.ar"
              defaultValue={usuario.email}
              ref={nombreEmailRef}
            />
          </Form.Group>

          <Form.Group className="mb-3 text-white" controlId="formBasicPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ingrese una contraseña"
              defaultValue={usuario.contrasena}
              ref={nombreContrasenaRef}
            />
          </Form.Group>

          <Button variant="success" type="submit">
            Editar
          </Button>
          {error === true ? (
            <Alert className="my-2 bg-white fw-bold text-dark">
              Debe completar todos los campos
            </Alert>
          ) : null}
        </Form>
      </div>
    </div>
  );
};

export default FormularioUsuariosEditar;
