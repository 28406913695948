import React, { useState } from "react";
import "./login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEye } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import ConsultaAdminPDF from "./VistaPDF.js/ConsultaAdminPDF";

const FormularioContacto = (props) => {
  const [show, setShow] = useState(false);
  const handlePDFClose = () => setShow(false);
  const handlePDFShow = () => setShow(true);
  const eliminarConsultaContacto = () => {
    Swal.fire({
      title: "¿Esta seguro de eliminar esta consulta?",
      text: "¡Una vez eliminada no se puede recuperar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Borrar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const URL =
            process.env.REACT_APP_API_URL_CONTACTO + "/" + props.consulta._id;

          const respuesta = await fetch(URL, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          });
          console.log(respuesta);
          if (respuesta.status === 200) {
            Swal.fire(
              "¡Eliminado!",
              "La consulta fue correctamente eliminada.",
              "success"
            );
            props.consultarContacto();
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  return (
    <tr>
      <td>{props.consulta.id}</td>
      <td>{props.consulta.nombre}</td>
      <td>{props.consulta.email}</td>
      <td>{props.consulta.whatsapp}</td>
      <td>
        <button>
          <FontAwesomeIcon
            icon={faEye}
            className="fs-2 me-2 text-muted"
            onClick={handlePDFShow}
          />
        </button>

        <button onClick={() => eliminarConsultaContacto()}>
          <FontAwesomeIcon
            icon={faTrashCan}
            className="fs-3 me-2 text-danger"
          />
        </button>
      </td>

      <Modal show={show} onHide={handlePDFClose}>
        <Modal.Header closeButton>
       </Modal.Header>
        <Modal.Body className="container pe-5">
          <ConsultaAdminPDF
            consulta={props.consulta}
            consultarContacto={props.consultarContacto}
          ></ConsultaAdminPDF>
        </Modal.Body>
      </Modal>
    </tr>
  );
};

export default FormularioContacto;
