import React from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const BienvenidaAdmin = () => {
  return (
    <div className="bgPaginaPrincipalAdmin d-flex flex-row">
         <Navbar
        collapseOnSelect="false"
        expand="xl"
        className="bgNavAdmin"
        variant="dark"
      >
        <Container className="d-flex flex-column justify-content-start align-content-start">
          <Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="fw-bold"
            />
            Panel Administrador{" "}
          </Navbar.Brand>

          <Navbar.Collapse>
            <Nav className="me-auto d-flex flex-column">
              <Nav.Link className="text-white text-decoration-none">
                <Link
                  to="/login/admin/panelusuarios"
                  className="text-decoration-none text-white"
                >
                  Panel de Usuarios
                </Link>
              </Nav.Link>

              <Nav.Link>
                {" "}
                <Link
                  className="text-white text-decoration-none "
                  to="/login/admin/nuevo-usuario"
                >
                  Agregar Usuario
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/login/admin/tablaprospectos"
                  className="text-decoration-none text-white"
                >
                  Panel Cotizaciones
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/login/admin/tablaconsultas"
                  className="text-decoration-none text-white"
                >
                  Panel Consultas
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="container m-5">
        <h2 className="text-center mb-3 fw-bold textAzul bgtitulosad">Bienvenido Admin</h2>
        <p className=" text-center fs-3 bgtitulosad">Selecciona las opciones disponibles del panel a su izquierda</p>

      </div>
    </div>
  );
};

export default BienvenidaAdmin;
