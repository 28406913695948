import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { campoRequerido, validarCorreo } from "../../Helpers/helpers.js";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./login.css";

const FormularioLogin = (props) => {
  const [emailUsuario, setEmailUsuario] = useState([]);
  const [contrasenaUsuario, setContrasenaUsuario] = useState([]);
  const navegacion = useNavigate();



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validarCorreo(emailUsuario) && campoRequerido(contrasenaUsuario)) {
      // crear objeto Usuario
      const nuevoUsuario = {
        email: emailUsuario,
        contrasena: contrasenaUsuario,
      };
      if (props.loginCtr === false) {
        let user = props.usuarios.find((usuario) => {
          return usuario.email === nuevoUsuario.email;
        });
        let admin = props.admins.find((usuario) => {
          return usuario.email === nuevoUsuario.email;
        });
        if (user !== undefined) {
          const validoUser = await (nuevoUsuario.contrasena, user.contrasena);
          if (validoUser) {
            props.setLoginCtr(true);
            navegacion("/login/users");
          }
        } else if (admin !== undefined) {
          const validoAdmin = await (nuevoUsuario.contrasena, admin.contrasena);
          if (validoAdmin) {
            props.setLoginCtr(true);
            props.setLoginCtrAd(true);
            navegacion("/login/admin");
          }
        } else if (admin !== undefined) {
          const validoAdmin = await (nuevoUsuario.contrasena, admin.contrasena);
          if (validoAdmin) {
            props.setLoginCtr(true);
            navegacion("/login/admin");
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "El usuario no existe!",
            footer: '<a href="">Más informacíon?</a>',
          });
        }
      } else {
        navegacion("/");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Todos los campos son obligatorios!",
        footer: '<a href="">Más informacíon?</a>',
      });
    }
  };
  return (
    <Form onSubmit={handleSubmit} className="bgFormuLogin p-5 fuenteMontserrat">
      <h2 className="text-white fw-bold">Bienvenido</h2>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="text-white">Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          maxlength="35"
          onChange={(e) => setEmailUsuario(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label className="text-white">Contraseña</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          maxlength="20"
          onChange={(e) => setContrasenaUsuario(e.target.value)}
        />
      </Form.Group>
      <Form.Text className="text-muted mb-2 d-flex justify-content-end">
        <Link to="/Error404" className="text-decoration-none text-white">
          ¿No tienes cuenta? Regístrate aqui.
        </Link>
      </Form.Text>

      <Button variant="primary" type="submit" className="text-uppercase fwMontserrat">
        ingresar
      </Button>
    </Form>
  );
};

export default FormularioLogin;
